import { createGlobalStyle } from 'styled-components';
import { white } from 'style/constants';

export default createGlobalStyle`
  body {
    background-color: ${white};
  }

  body, h1, h2, h3, h4, h5, h6, p, a, ul, ol, li, div, section, main {
    padding: 0;
    margin: 0;
  }
`;
