import styled from 'styled-components';
import {
  blueNavy,
  fontSizeLg,
  fontSizeXxl,
  openSans,
  tabletWidth,
} from 'style/constants';
import {} from './constants';

const H3 = styled.h3`
  font-size: ${fontSizeXxl};
  line-height: 1.6;
  font-family: ${openSans};
  font-weight: 400;
  color: ${blueNavy};

  @media (max-width: ${tabletWidth}) {
    font-size: ${fontSizeLg};
  }
`;

export default H3;
