import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H2, P } from 'style';
import {
  backgroundGray,
  desktopWidth,
  fontSizeMd,
  fontSizeLg,
  labelGray,
  mobileHdWidth,
  paddingWrapperSm,
  paddingWrapperMd,
  paddingWrapperXl,
  paddingWrapperXxl,
  paddingLg,
  tabletWidth,
  white,
} from 'style/constants';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${paddingWrapperXxl} ${paddingWrapperMd};
  box-sizing: border-box;
  background-color: ${props => (props.dark ? backgroundGray : white)};

  @media (max-width: ${tabletWidth}) {
    padding-top: ${paddingWrapperXl};
    padding-bottom: ${paddingWrapperXl};
  }

  @media (max-width: ${mobileHdWidth}) {
    padding-left: ${paddingWrapperSm};
    padding-right: ${paddingWrapperSm};
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${desktopWidth};
`;

const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  h2 {
    text-align: center;
  }

  p {
    max-width: 650px;
    text-align: center;
    color: ${labelGray};
    font-size: ${fontSizeLg};
    padding-top: ${paddingLg};

    @media (max-width: ${tabletWidth}) {
      font-size: ${fontSizeMd};
    }
  }
`;

const SectionWrapper = ({ children, dark, title, description, button }) => (
  <Container dark={dark}>
    <Wrapper>
      <Header>
        <H2>{title}</H2>
        {description && <P>{description}</P>}
      </Header>
      {children}
    </Wrapper>
  </Container>
);

SectionWrapper.propTypes = {
  dark: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  button: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

export default SectionWrapper;
