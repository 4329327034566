/* BORDER RADII */
export const borderRadiusSm = '2px';
export const borderRadiusMd = '3px';
export const borderRadiusLg = '5px';

/* COLORS */
export const white = '#ffffff';
export const backgroundGray = '#f8f9ff';
export const backgroundDark = '#edeff4';
export const grayDark = '#e1e2e2';
export const lightGray = '#949494';
export const labelGray = '#6e6e6e';
export const bodyGray = '#494949';
export const black = '#000000';

export const blueGrayLight = '#eef1fe';
export const blueGrayActive = '#cfd3ee';
export const blueGrayDark = '#b3baf9';
export const blueActive = '#4355f3';
export const blueDark = '#0f40d8';
export const blueNavy = '#16244f';

export const purpleActive = '#f4ddff';
export const purpleDark = '#ad06ff';

export const greenActive = '#c6f6d5';
export const greenDark = '#22543d';

export const redActive = '#fed7d7';
export const redDark = '#e32929';


/* FONTS */
export const systemFont = `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`;
export const montserrat = `'Montserrat', ${systemFont}`;
export const openSans = `'Open Sans', ${systemFont}`;

/* FONT SIZES */
export const fontSizeXxs = '12px';
export const fontSizeXs = '14px';
export const fontSizeSm = '16px';
export const fontSizeMd = '18px';
export const fontSizeLg = '20px';
export const fontSizeXl = '24px';
export const fontSizeXxl = '28px';
export const fontSizeXxxl = '32px';
export const fontSizeXxxxl = '38px';

/* PADDING */
export const paddingWrapperSm = '23px';
export const paddingWrapperMd = '30px';
export const paddingWrapperLg = '40px';
export const paddingWrapperXl = '60px';
export const paddingWrapperXxl = '70px';
export const paddingWrapperXxxl = '130px';
export const paddingSm = '5px';
export const paddingMd = '10px';
export const paddingLg = '15px';
export const paddingXl = '20px';
export const paddingXxl = '30px';

/* SCREEN SIZES */
export const mobileWidth = '475px';
export const mobileHdWidth = '575px';
export const mobileNavWidth = '625px';
export const tabletWidth = '800px';
export const desktopWidth = '1275px';

/* SHADOWS */
export const cardShadow = '0 4px 12px -3px rgba(0, 0, 0, 0.2)';

/* TRANSITIONS */
export const transitionSimpleXtraFast = '0.1s ease-out';
