import styled from 'styled-components';
import {
  blueNavy,
  fontSizeXxxl,
  fontSizeXxxxl,
  montserrat,
  tabletWidth,
} from 'style/constants';

const H1 = styled.h1`
  font-size: ${fontSizeXxxxl};
  font-family: ${montserrat};
  font-weight: 600;
  color: ${blueNavy};

  @media (max-width: ${tabletWidth}) {
    font-size: ${fontSizeXxxl};
  }
`;

export default H1;
