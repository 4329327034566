import React, { Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HeroDesktopIllustration, HeroMobileIllustration } from 'components';
import { Button, H1, P } from 'style';
import {
  backgroundGray,
  blueActive,
  desktopWidth,
  fontSizeXxxl,
  mobileHdWidth,
  mobileNavWidth,
  tabletWidth,
  paddingLg,
  paddingXl,
  paddingWrapperSm,
  paddingWrapperMd,
  paddingWrapperLg,
  paddingWrapperXxl,
} from 'style/constants';
import WaveSvg from 'static/img/wave.svg';

const tabletBreak = '1000px';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${paddingWrapperMd};
  box-sizing: border-box;

  @media (max-width: ${mobileHdWidth}) {
    padding: 0 ${paddingWrapperSm};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  width: 100%;
  max-width: ${desktopWidth};
`;

const IllustrationContainer = styled.div`
  width: 100%;
  max-width: calc(100% - 25%);
  min-width: 800px;
  border-radius: 50%;
  margin-left: 25%;
  position: relative;
  padding-left: 50px;
  transform: translateY(-${props => props.top}px);

  @media (max-width: ${tabletBreak}) {
    min-width: 650px;
  }

  @media (max-width: ${tabletWidth}) {
    display: none;
  }
`;

const IllustrationWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  background: ${blueActive};
  border-radius: 50%;
  transform: translate(20%, -10%);
  z-index: 50;

  @media (max-width: ${tabletBreak}) {
    transform: translate(20%, -6%);
  }
`;

const TextWrapper = styled.div`
  max-width: 400px;
  position: absolute;
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: ${paddingWrapperXxl};

  h1 {
    margin-bottom: ${paddingLg};

    @media (max-width: ${tabletBreak}) {
      font-size: ${fontSizeXxxl};
    }
  }

  p {
    margin-bottom: ${paddingXl};
    line-height: 1.5;
    width: 100%;
    max-width: 340px;
  }

  @media (min-width: ${tabletWidth}) {
    max-width: 330px;
  }

  @media (max-width: 900px) and (min-width: ${tabletWidth}) {

    p {
      max-width: 320px;
    }
  }

  @media (max-width: ${tabletWidth}) {
    position: relative;
    padding-top: ${paddingWrapperMd};
    padding-bottom: ${paddingWrapperMd};
  }

  @media (max-width: ${mobileNavWidth}) {
    padding-top: ${paddingWrapperSm};
  }
`;

const Wave = styled(WaveSvg)`
  margin-top: -10rem;
  fill: ${backgroundGray};
  width: 100%;
  display: block;

  @media (max-width: ${tabletWidth}) {
    display: none;
  }
`;

const MobileWrapper = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;

  @media (max-width: ${tabletWidth}) {
    display: flex;
  }
`;

const Hero = ({ height: navHeight, title, description, button }) => {
  return (
    <Fragment>
      <Container>
        <Wrapper>
          <TextWrapper>
            <H1>{title}</H1>
            <P>{description}</P>
            {button && <Button to={button.href.url}>{button.text}</Button>}
          </TextWrapper>
          <IllustrationContainer top={navHeight}>
            <IllustrationWrapper>
              <HeroDesktopIllustration show />
            </IllustrationWrapper>
          </IllustrationContainer>
        </Wrapper>
      </Container>
      <Wave />
      <MobileWrapper>
        <HeroMobileIllustration show />
      </MobileWrapper>
    </Fragment>
  );
};

Hero.propTypes = {
  height: PropTypes.number.isRequired,
};

const mapStateToProps = state => state.nav;

export default connect(
  mapStateToProps,
  {}
)(Hero);
