import styled from 'styled-components';
import {
  blueNavy,
  fontSizeXxl,
  fontSizeXxxl,
  montserrat,
  tabletWidth,
} from 'style/constants';

const H2 = styled.h2`
  font-size: ${fontSizeXxxl};
  font-family: ${montserrat};
  font-weight: 600;
  color: ${blueNavy};
  padding-bottom: 20px;
  @media (max-width: ${tabletWidth}) {
    font-size: ${fontSizeXxl};
  }
`;

export default H2;
