import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Differentiator, SectionWrapper } from 'components';
import {
  mobileWidth,
  paddingWrapperMd,
  paddingWrapperLg,
  paddingWrapperXl,
  paddingXxl,
  tabletWidth,
} from 'style/constants';

const DiffContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  margin-bottom: -${paddingWrapperMd};

  @media (min-width: ${tabletWidth}) {
    padding-top: ${paddingWrapperXl};
  }

  @media (min-width: ${mobileWidth}) and (max-width: ${tabletWidth}) {
    padding-top: ${paddingWrapperLg};
  }

  @media (min-width: ${mobileWidth}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const DiffWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${paddingWrapperMd};

  @media (min-width: 925px) {
    width: calc(
      (100% / ${props => props.count}) -
        (
          (${props => props.count} - 1) *
            (${paddingXxl} / ${props => props.count})
        )
    );
    margin-left: 25px;

    &:nth-child(4n + 1) {
      margin-left: 0;
    }
  }

  @media (min-width: ${mobileWidth}) and (max-width: 925px) {
    width: calc(50% - (${paddingXxl} / 2));
    margin-left: ${paddingXxl};

    &:nth-child(2n + 1) {
      margin-left: 0;
    }
  }
`;

class Differentiators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      differentiators: this.parseDifferentiators(props.differentiators),
    };
    this.parseDifferentiators = this.parseDifferentiators.bind(this);
  }

  parseDifferentiators(differentiators) {
    return differentiators.map(
      ({ title, description, is_available_now, icon }) => {
        return {
          title: title.text,
          description: description.text,
          is_available_now: is_available_now === 'true',
          icon,
        };
      }
    );
  }

  componentWillReceiveProps(newProps, oldProps) {
    this.setState({
      differentiators: this.parseDifferentiators(newProps.differentiators),
    });
  }

  render() {
    const { differentiators } = this.state;
    return (
      <SectionWrapper {...this.props}>
        <DiffContainer>
          {differentiators.map((data, i) => (
            <DiffWrapper key={i} count={Math.min(4, differentiators.length)}>
              <Differentiator {...data} />
            </DiffWrapper>
          ))}
        </DiffContainer>
      </SectionWrapper>
    );
  }
}

export const query = graphql`
  fragment DifferentiatorsSection on PrismicSectionBodyDifferentiators {
    items {
      differentiator {
        document {
          data {
            title {
              text
            }
            description {
              text
            }
            is_available_now
            icon
          }
        }
      }
    }
  }
`;

export default Differentiators;
