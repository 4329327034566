import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { Button, H2, P } from 'style';
import {
  blueActive,
  borderRadiusMd,
  borderRadiusLg,
  desktopWidth,
  mobileHdWidth,
  paddingSm,
  paddingWrapperSm,
  paddingWrapperMd,
  paddingWrapperXl,
  paddingWrapperXxl,
  tabletWidth,
  white,
} from 'style/constants';
import { updateParallax } from 'utils';
import ClockIcon from 'static/img/clock.svg';
import UpdateIcon from 'static/img/update.svg';
import UserIcon from 'static/img/user.svg';

const Container = styled.div`
  position: relative;
  padding: ${paddingWrapperXxl} ${paddingWrapperMd};
  box-sizing: border-box;
  background-color: ${blueActive};
  overflow: hidden;

  @media (max-width: ${tabletWidth}) {
    padding: ${paddingWrapperXl} ${paddingWrapperMd};
  }

  @media (max-width: ${mobileHdWidth}) {
    padding: ${paddingWrapperXl} ${paddingWrapperSm};
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: ${desktopWidth};
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 6;
  padding-top: ${paddingSm};

  h2 {
    color: ${white};
    padding-bottom: ${paddingWrapperMd};
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
`;

const Gradient = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax || 0}px, 0px)`,
    transform: `translate3d(0px, ${parallax || 0}px, 0px)`,
  },
}))`
  position: absolute;
  top: -8%;
  left: 20%;
  width: 17%;
  height: 55%;
  opacity: 0.3;
  border-radius: ${borderRadiusLg};
  background: linear-gradient(to bottom, #c5d5fb, #c5d5fb00 110%);
  will-change: transform;

  ${props =>
    props.right &&
    css`
      top: initial;
      bottom: 10%;
      left: initial;
      right: -4%;
      width: 20%;
      height: 60%;
      z-index: 5;
    `};
`;

const TopDarkCard = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax || 0}px, 0px)`,
    transform: `translate3d(0px, ${parallax || 0}px, 0px)`,
  },
}))`
  position: absolute;
  left: 34%;
  top: -16%;
  width: 8%;
  height: 30%;
  background: #1f3fc0;
  border-radius: ${borderRadiusLg};
  opacity: 0.7;
  z-index: 50;
  box-sizing: border-box;
  will-change: transform;

  &::after {
    content: '';
    position: absolute;
    bottom: 15%;
    left: 10%;
    width: calc(100% - 20%);
    height: 10%;
    border-radius: 10px;
    background: #6d7bf5;
  }
`;

const ChipWrapper = styled.div.attrs(({ parallax, moveX }) => ({
  style: {
    WebkitTransform: `translate3d(${moveX ? parallax || 0 : 0}px, ${
      moveX ? 0 : parallax || 0
    }px, 0px)`,
    transform: `translate3d(${moveX ? parallax || 0 : 0}px, ${
      moveX ? 0 : parallax || 0
    }px, 0px)`,
  },
}))`
  position: absolute;
  left: 15%;
  top: 27%;
  width: 10%;
  height: 11%;
  background: ${white};
  opacity: 0.2;
  border-radius: 20px;
  will-change: transform;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 22%;
    transform: translateY(-50%);
    width: 68%;
    height: 25%;
    border-radius: 10px;
    background: linear-gradient(to right, #485ad0 50%, #485ad000 110%);
  }

  ${props =>
    props.right &&
    css`
      top: 41%;
      left: initial;
      right: 11%;
      background: #1f3fc0;
      opacity: 0.7;
      transform: scale(0.8);
      z-index: 6;

      &::after {
        opacity: 0.7;
        background: #6d7bf5;
      }
    `};

  ${props =>
    props.time &&
    css`
      top: initial;
      bottom: 16%;
      left: initial;
      right: 0%;

      &::after {
        background: #485ad0;
      }
    `} ${props =>
    props.left &&
    css`
      top: initial;
      bottom: 16%;
      left: 1.5%;
      opacity: 0.7;
      background: #1f3fc0;
      transform: scale(0.7);

      &::after {
        opacity: 0.7;
        background: #6d7bf5;
      }
    `};
`;

const ChipIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 7%;
  width: 10%;
  padding-top: 10%;
  border-radius: 50%;
  background: #485ad0;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45%;
    height: 45%;
    border-radius: 50%;
    background: ${white};
  }

  ${props =>
    props.right &&
    css`
      opacity: 0.7;
      background: #6d7bf5;

      &::before {
        background: #1f3fc0;
      }
    `};

  ${props =>
    (props.time || props.left) &&
    css`
      background: none;

      &::before {
        display: none;
      }

      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        stroke-width: 2.5;
        stroke: ${props => (props.time ? '#485ad0' : '#6d7bf5')};
      }
    `};
`;

const Chip = ({ parallax, moveX, right, time, left }) => (
  <ChipWrapper
    moveX={moveX}
    parallax={parallax}
    right={right}
    time={time}
    left={left}
  >
    <ChipIcon right={right} time={time} left={left}>
      {(time || left) && <ClockIcon />}
    </ChipIcon>
  </ChipWrapper>
);

const RPCard = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax || 0}px, 0px)`,
    transform: `translate3d(0px, ${parallax || 0}px, 0px)`,
  },
}))`
  position: absolute;
  left: 12%;
  top: 70%;
  background: ${white};
  width: 11%;
  opacity: 0.2;
  border-radius: ${borderRadiusLg};
  padding: 15px;
  box-sizing: border-box;
  will-change: transform;
`;

const RPCardTop = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

const RPCardProfile = styled.div`
  width: 20%;
  padding-top: 20%;
  background: #485ad0;
  border-radius: ${borderRadiusMd};
  opacity: 0.6;
  flex-shrink: 0;
  margin-right: 15px;
`;

const RPCardTitle = styled.div`
  width: 60%;
  height: 12px;
  border-radius: 6px;
  background: #485ad0;
`;

const RPCardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RPCardDescription = styled.div`
  width: 60%;
  height: 10px;
  border-radius: 5px;
  background: #485ad0;
  margin-bottom: 10px;

  &:last-child {
    width: 90%;
    background: linear-gradient(to right, #485ad0, #485ad000);
  }
`;

const RiskProfileCard = ({ parallax }) => (
  <RPCard parallax={parallax}>
    <RPCardTop>
      <RPCardProfile />
      <RPCardTitle />
    </RPCardTop>
    <RPCardBody>
      <RPCardDescription />
      <RPCardDescription />
    </RPCardBody>
  </RPCard>
);

const CollaboratorWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(${parallax || 0}px, 0px, 0px)`,
    transform: `translate3d(${parallax || 0}px, 0px, 0px)`,
  },
}))`
  position: absolute;
  left: 35%;
  top: 74%;
  width: 9%;
  height: 12%;
  opacity: 0.7;
  background: #1f3fc0;
  border-radius: ${borderRadiusLg};
  will-change: transform;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 34%;
    height: 16%;
    width: 30%;
    background: #7a94e8;
    border-radius: 10px;
    opacity: 0.5;
  }

  &::before {
    top: 27%;
  }

  &::after {
    bottom: 27%;
    width: 52%;
  }

  ${props =>
    props.left &&
    css`
      top: 42%;
      left: -2%;
    `} ${props =>
    props.right &&
    css`
      top: 11%;
      left: initial;
      right: -2%;

      &::before,
      &::after {
        left: 24%;
      }

      &::before {
        width: 26%;
      }
    `};
`;

const CollaboratorIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 15%;
  transform: translateY(-50%);
  width: 11%;
  padding-top: 11%;
  border-radius: 50%;
  background: #7a94e8;
  opacity: 0.5;

  ${props =>
    props.right &&
    css`
      left: 8%;
    `};
`;

const CollaboratorCard = ({ parallax, left, right }) => (
  <CollaboratorWrapper parallax={parallax} left={left} right={right}>
    <CollaboratorIcon left={left} right={right} />
  </CollaboratorWrapper>
);

const FloatingDescWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(${parallax || 0}px, 0px, 0px)`,
    transform: `translate3d(${parallax || 0}px, 0px, 0px)`,
  },
}))`
  position: absolute;
  left: 26%;
  top: 52%;
  width: 8%;
  height: 8%;
  opacity: 0.4;
  will-change: transform;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 10%;
    left: 26%;
    width: 40%;
    height: 30%;
    background: #828ef9;
    border-radius: 10px;
  }

  &::after {
    top: 60%;
    width: 72%;
  }

  ${props =>
    props.right &&
    css`
      top: 63%;
      left: initial;
      right: 28.5%;
      width: 10%;

      &::before,
      &::after {
        top: 5%;
        left: 22%;
      }

      &::after {
        top: 65%;
      }
    `};

  ${props =>
    props.bottom &&
    css`
      top: initial;
      bottom: 6%;
      left: initial;
      right: 16.5%;
      width: 11%;
      opacity: 0.6;

      &::before,
      &::after {
        left: 15%;
        height: 40%;
        top: 0%;
        background: #1f3fc0;
      }

      &::before {
        width: 45%;
      }

      &::after {
        top: 55%;
        background: linear-gradient(to right, #1f3fc0 -10%, #1f3fc000 120%);
      }
    `};
`;

const FloatingDescIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 18%;
  padding-top: 18%;
  border-radius: 50%;
  background: #828ef9;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    color: #1f3fc0;
    stroke-width: 2.5;
  }

  ${props =>
    props.right &&
    css`
      left: 4%;
      width: 12%;
      padding-top: 12%;
    `};

  ${props =>
    props.bottom &&
    css`
      width: 10%;
      padding-top: 10%;
      background: #1f3fc0;
    `};
`;

const FloatingDesc = ({ parallax, bottom, right }) => (
  <FloatingDescWrapper parallax={parallax} right={right} bottom={bottom}>
    <FloatingDescIcon right={right} bottom={bottom}>
      {!right && !bottom && <UpdateIcon />}
    </FloatingDescIcon>
  </FloatingDescWrapper>
);

const BottomCard = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax || 0}px, 0px)`,
    transform: `translate3d(0px, ${parallax || 0}px, 0px)`,
  },
}))`
  position: absolute;
  top: 83%;
  right: 35.5%;
  width: 10%;
  height: 20%;
  background: #1f3fc0;
  opacity: 0.7;
  border-radius: ${borderRadiusLg};
  will-change: transform;

  &::after {
    content: '';
    position: absolute;
    top: 25%;
    left: -7.5%;
    width: 15%;
    padding-top: 15%;
    border-radius: 50%;
    background-color: #6573f4;
  }

  svg {
    position: absolute;
    left: -6%;
    top: 17%;
    width: 12%;
    stroke-width: 3;
    color: #1f3fc0;
    z-index: 10;
  }
`;

const ClaimCardWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(${parallax || 0}px, 0px, 0px)`,
    transform: `translate3d(${parallax || 0}px, 0px, 0px)`,
  },
}))`
  position: absolute;
  top: -5%;
  right: 28%;
  width: 13%;
  height: 30%;
  background: ${white};
  opacity: 0.2;
  border-radius: ${borderRadiusLg};
  will-change: transform;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 27%;
    width: 63%;
    height: 10%;
    border-radius: 10px;
    background: #485ad0;
  }

  &::before {
    bottom: 33%;
    width: 45%;
    background: linear-gradient(to right, #485ad0 10%, #485ad000 120%);
  }

  &::after {
    bottom: 18%;
  }

  @media (max-width: ${tabletWidth}) {
    right: 31%;
  }

  @media (max-width: ${mobileHdWidth}) {
    top: -10%;
    right: 33%;
  }
`;

const ClaimCardIcon = styled.div`
  position: absolute;
  bottom: 17.5%;
  left: 10%;
  width: 10%;
  padding-top: 10%;
  border-radius: 50%;
  background: #1f3fc0;
`;

const ClaimCard = ({ parallax }) => (
  <ClaimCardWrapper parallax={parallax}>
    <ClaimCardIcon />
  </ClaimCardWrapper>
);

const FloatingIconWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax || 0}px, 0px)`,
    transform: `translate3d(0px, ${parallax || 0}px, 0px)`,
  },
}))`
  position: absolute;
  top: 10%;
  left: 8%;
  width: 2%;
  padding-top: 2%;
  border-radius: 50%;
  background: #1f3fc0;
  opacity: 0.7;
  transform: scale(0.8);
  will-change: transform;

  svg {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    color: ${white};
    opacity: 0.2;
    stroke-width: 2.5;
  }

  ${props =>
    props.right &&
    css`
      top: 5%;
      left: initial;
      right: 20%;
      transform: scale(0.9);
    `};
`;

const FloatingIcon = ({ parallax, right }) => (
  <FloatingIconWrapper parallax={parallax} right={right}>
    <UserIcon />
  </FloatingIconWrapper>
);

class CTA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parallax: {
        rpCard: {
          depth: 0.05,
          height: 10,
          initialHeight: 10,
          endHeight: 80,
        },
        topChip: {
          depth: -0.05,
          height: 20,
          initialHeight: 20,
          endHeight: -100,
        },
        floatingIcon: {
          depth: 0.1,
          height: -50,
          initialHeight: -50,
          endHeight: 200,
        },
        claimCard: {
          depth: -0.025,
          height: 50,
          initialHeight: 50,
          endHeight: -200,
        },
        bottomCard: {
          depth: -0.025,
          height: 20,
          initialHeight: 20,
          endHeight: -100,
        },
        rightFloatingDesc: {
          depth: 0.025,
          height: -20,
          initialHeight: -20,
          endHeight: 100,
        },
        topDarkCard: {
          depth: 0.05,
          height: -20,
          initialHeight: -20,
          endHeight: 100,
        },
        leftChip: {
          depth: -0.025,
          height: 0,
          initialHeight: 0,
          endHeight: -200,
        },
      },
    };

    this.updateParallax = this.updateParallax.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    if (this.state.parallax) {
      window.requestAnimationFrame(this.updateParallax);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    window.cancelAnimationFrame(this.updateParallax);
  }

  updateParallax() {
    if (this.el) {
      const parallax = updateParallax(this.el, 0, this.state.parallax);
      if (parallax) {
        return this.setState(
          {
            parallax,
          },
          () => {
            window.requestAnimationFrame(this.updateParallax);
          }
        );
      }
    }
    window.requestAnimationFrame(this.updateParallax);
  }

  setState(newState, props) {
    if (this.mounted) {
      super.setState(newState, props);
    }
  }

  render() {
    const { parallax } = this.state;
    const { title, description, button } = this.props;
    return (
      <Container>
        <Wrapper>
          <H2>{title}</H2>
          {description && <P>{description}</P>}
          <Button inverse to={button.href.url}>
            {button.text}
          </Button>
        </Wrapper>
        <Background ref={e => (this.el = e)}>
          <FloatingIcon parallax={parallax.floatingIcon.height} />
          <FloatingIcon right parallax={-1 * parallax.floatingIcon.height} />
          <Chip left moveX parallax={parallax.leftChip.height} />
          <Chip parallax={parallax.topChip.height} />
          <Chip right />
          <Chip time parallax={parallax.topChip.height} />
          <FloatingDesc />
          <FloatingDesc right />
          <FloatingDesc bottom parallax={parallax.rightFloatingDesc.height} />
          <TopDarkCard parallax={parallax.topDarkCard.height} />
          <Gradient />
          <Gradient right parallax={-1 * parallax.topChip.height} />
          <RiskProfileCard parallax={parallax.rpCard.height} />
          <CollaboratorCard left />
          <CollaboratorCard parallax={parallax.rpCard.height} />
          <CollaboratorCard right />
          <ClaimCard parallax={parallax.claimCard.height} />
          <BottomCard parallax={parallax.bottomCard.height}>
            <UserIcon />
          </BottomCard>
        </Background>
      </Container>
    );
  }
}

export default CTA;
