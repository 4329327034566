import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { borderRadiusLg, cardShadow, grayDark, white } from 'style/constants';
import { updateParallax } from 'utils';
import ClockIcon from 'static/img/clock.svg';
import dashboardContentSvg from 'static/img/cards/dashboard.svg';
import UpdateIcon from 'static/img/update.svg';
import UserIcon from 'static/img/user.svg';
import WaveContent from 'static/img/wave.svg';

const WaveContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &::after {
    content: '';
    position: relative;
    width: 100%;
    padding-top: 60%;
    background: #2a40b1;
  }
`;

const Wave = styled(WaveContent)`
  display: block;
  width: 100%;
  fill: #2a40b1;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

const DashboardCardWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax}px, 0px)`,
    transform: `translate3d(0px, ${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  top: 16%;
  right: -5%;
  width: 72%;
  height: 58%;
  border-radius: ${borderRadiusLg};
  border: 1px solid ${grayDark};
  box-sizing: border-box;
  background-color: ${white};
  box-shadow: ${cardShadow};
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  overflow: hidden;
  z-index: 15;
`;

const DashboardHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 8%;
  z-index: 10;
`;

const DashboardContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  z-index: 9;
`;

const DashboardContent = styled(dashboardContentSvg).attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, -${parallax}px, 0px)`,
    transform: `translate3d(0px, -${parallax}px, 0px)`,
  },
}))`
  width: 100%;
  height: auto;
  margin-bottom: -4px;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
`;

const DashboardCard = ({ parallax, contentParallax }) => (
  <DashboardCardWrapper parallax={parallax}>
    <DashboardHeader />
    <DashboardContentWrapper>
      <DashboardContent parallax={contentParallax} />
    </DashboardContentWrapper>
  </DashboardCardWrapper>
);

const ChipWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(${parallax || 0}px, 25%, 0px)`,
    transform: `translate3d(${parallax || 0}px, 25%, 0px)`,
  },
}))`
  position: absolute;
  left: -10%;
  bottom: 0%;
  width: 50%;
  height: 8%;
  background: #dfb1fd;
  opacity: 1;
  border-radius: 30px;
  will-change: transform;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 7%;
    transform: translateY(-50%);
    width: 70%;
    height: 30%;
    opacity: 1;
    border-radius: 15px;
    background: #c78aef;
    background-clip: padding-box;
  }
`;

const ChipIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 7%;
  width: 10%;
  padding-top: 10%;
  border-radius: 50%;
  opacity: 1;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    stroke-width: 2.5;
    color: #c78aef;
  }
`;

const Chip = ({ parallax }) => (
  <ChipWrapper parallax={parallax}>
    <ChipIcon>
      <ClockIcon />
    </ChipIcon>
  </ChipWrapper>
);

const CollaboratorWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax || 0}px, 0px)`,
    transform: `translate3d(0px, ${parallax || 0}px, 0px)`,
  },
}))`
  position: absolute;
  left: 25%;
  top: 48%;
  width: 30%;
  height: 9%;
  background: #3a67f2;
  border-radius: ${borderRadiusLg};
  will-change: transform;
  z-index: 16;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 32%;
    height: 15%;
    width: 36%;
    background: #7a94e8;
    border-radius: 10px;
    opacity: 1;
  }

  &::before {
    top: 27%;
  }

  &::after {
    bottom: 27%;
    width: 55%;
    background: linear-gradient(to right, #7a94e8, #7a94e800 120%);
    background-clip: padding-box;
  }
`;

const CollaboratorIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 9%;
  transform: translateY(-50%);
  width: 14%;
  padding-top: 14%;
  border-radius: 50%;
  background: #88a0ef;
  opacity: 1;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    stroke-width: 2.5;
    color: #3a67f2;
  }
`;

const CollaboratorCard = ({ parallax }) => (
  <CollaboratorWrapper parallax={parallax}>
    <CollaboratorIcon>
      <UpdateIcon />
    </CollaboratorIcon>
  </CollaboratorWrapper>
);

const FloatingIconWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax || 0}px, 0px)`,
    transform: `translate3d(0px, ${parallax || 0}px, 0px)`,
  },
}))`
  position: absolute;
  bottom: 20%;
  right: 8%;
  width: 7%;
  padding-top: 7%;
  border-radius: 50%;
  background: #3e66df;
  opacity: 0.7;
  transform: scale(0.8);
  will-change: transform;

  svg {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    color: #2a40b1;
    stroke-width: 2.5;
  }
`;

const FloatingIcon = ({ parallax }) => (
  <FloatingIconWrapper parallax={parallax}>
    <UserIcon />
  </FloatingIconWrapper>
);

const ThreeLinesWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, -${parallax}px, 0px)`,
    transform: `translate3d(0px, -${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  right: 36%;
  bottom: 13%;
  width: 10%;
  height: 2%;
  opacity: 0.45;

  div {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: #09238b;
    background-clip: padding-box;
    backface-visibility: hidden;
    perspective: 1000;
    will-change: transform;
    z-index: 12;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0%;
      transform: translateX(200%);
      height: 100%;
      width: 18%;
      border-radius: 10px;
      background: #09238b;
      background-clip: padding-box;
    }

    &:nth-child(2) {
      transform: translate(70%, 200%);
    }

    &:nth-child(3) {
      transform: translate(10%, 400%);

      &::before {
        left: 0%;
        right: initial;
        transform: translateX(-200%);
      }
    }
  }
`;

const ThreeLines = ({ parallax }) => (
  <ThreeLinesWrapper parallax={parallax}>
    {[0, 1, 2].map(i => (
      <div key={i} />
    ))}
  </ThreeLinesWrapper>
);

const DoubleLine = styled.div`
  position: absolute;
  bottom: 33%;
  left: 10%;
  width: 18%;
  height: 2%;
  border-radius: 10px;
  background: linear-gradient(to right, #4166db, #4166db00 120%);
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18%;
    transform: translateY(200%);
    height: 100%;
    width: 150%;
    border-radius: 15px;
    background: #4166db;
    background-clip: padding-box;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-200%);
    height: 100%;
    width: 10%;
    border-radius: 15px;
    background: #4166db;
    background-clip: padding-box;
  }

  ${props =>
    props.dark &&
    css`
      bottom: initial;
      top: 30%;
      left: -5%;
      background: #09238b;
      opacity: 0.65;

      &::before {
        left: 63%;
        width: 80%;
        background: #09238b;
      }

      &::after {
        left: 45%;
        transform: translateY(200%);
        background: #09238b;
      }
    `};
`;

class HeroMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parallax: {
        dashboardCard: {
          depth: -0.05,
          height: 0,
          initialHeight: 0,
          endHeight: -50,
        },
        dashboardContent: {
          depth: 0.1,
          height: 0,
          initialHeight: 0,
          endHeight: 130,
        },
        chip: {
          depth: 0.05,
          height: 0,
          initialHeight: 0,
          endHeight: 100,
        },
        collabCard: {
          depth: 0.025,
          height: 0,
          initialHeight: 0,
          endHeight: 100,
        },
      },
    };

    this.updateParallax = this.updateParallax.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    if (this.state.parallax) {
      window.requestAnimationFrame(this.updateParallax);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    window.cancelAnimationFrame(this.updateParallax);
  }

  updateParallax() {
    if (this.el) {
      const parallax = updateParallax(this.el, 0, this.state.parallax, true);
      if (parallax) {
        return this.setState(
          {
            parallax,
          },
          () => {
            window.requestAnimationFrame(this.updateParallax);
          }
        );
      }
    }
    window.requestAnimationFrame(this.updateParallax);
  }

  setState(newState, props) {
    if (this.mounted) {
      super.setState(newState, props);
    }
  }

  renderIllustrations(parallax) {
    return (
      <Fragment>
        <CollaboratorCard parallax={parallax.collabCard.height} />
        <Chip parallax={parallax.chip.height} />
        <DashboardCard
          parallax={parallax.dashboardCard.height}
          contentParallax={parallax.dashboardContent.height}
        />
        <FloatingIcon />
        <ThreeLines />
        <DoubleLine />
        <DoubleLine dark />
      </Fragment>
    );
  }

  render() {
    return (
      <WaveContainer>
        <Wave />
        <Wrapper ref={e => (this.el = e)}>
          {this.renderIllustrations(this.state.parallax)}
        </Wrapper>
      </WaveContainer>
    );
  }
}

export default HeroMobile;
