import React from 'react';
import styled from 'styled-components';
import { Link } from 'style';
import { mobileNavWidth, white } from 'style/constants';
import ColorLogo from 'static/img/logo/color.svg';
import WhiteLogo from 'static/img/logo/white.svg';

const Container = styled.div.attrs({
  className: 'logo',
})`
  display: block;

  a,
  div {
    outline: none;
    width: 100%;
    max-width: 200px;
    display: block;
  }

  svg {
    width: 100%;
    color: ${white};

    &:first-child {
      display: none;
    }

    @media (max-width: ${mobileNavWidth}) {
      &:first-child {
        display: ${props => (props.white ? 'block' : 'none')};
      }

      &:last-child {
        display: ${props => (props.white ? 'none' : 'block')};
      }
    }
  }
`;

const Logo = ({ isLink, onClick, white }) => {
  const Wrapper = isLink ? Link : 'div';
  const linkProps = isLink
    ? {
        to: '/',
        title: 'Wunderite',
      }
    : {};
  return (
    <Container white={white} onClick={onClick}>
      <Wrapper {...linkProps}>
        <WhiteLogo />
        <ColorLogo />
      </Wrapper>
    </Container>
  );
};

export default Logo;
