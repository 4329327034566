import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Award, SectionWrapper } from 'components';
import {
  mobileHdWidth,
  paddingWrapperMd,
  paddingWrapperLg,
  paddingXl,
  tabletWidth,
} from 'style/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${paddingWrapperMd};
  margin-bottom: -${paddingWrapperMd};

  @media (min-width: ${mobileHdWidth}) {
    padding-bottom: ${paddingXl};
    padding-top: ${paddingWrapperLg};
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  flex-direction: column;
  width: 100%;
  padding-bottom: ${paddingWrapperMd};

  @media (min-width: ${tabletWidth}) {
    width: calc((100% / 3) - (3 * (${paddingWrapperMd} / 3)));
    margin-left: ${paddingWrapperMd};

    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }

  @media (min-width: ${mobileHdWidth}) and (max-width: ${tabletWidth}) {
    width: calc(50% - (${paddingWrapperMd} / 3));
    margin-left: ${paddingWrapperMd};

    &:nth-child(3n + 1) {
      margin-left: 0;
    }
  }

  @media (max-width: ${mobileHdWidth}) {
    display: ${props => (props.hideMobile ? 'none' : 'flex')};
  }
`;

class Awards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      awards: this.parseAwards(props.awards),
    };
    this.parseAwards = this.parseAwards.bind(this);
  }

  parseAwards(awards) {
    return awards.map(
      ({ organization, description, logo, link }) => {
        return {
          organization,
          description: description.text,
          logo: logo.url,
          link: link ? link.url : null,
        };
      }
    );
  }

  componentWillReceiveProps(newProps, oldProps) {
    this.setState({
      awards: this.parseAwards(newProps.awards),
    });
  }

  render() {
    const { awards } = this.state;
    return (
      <SectionWrapper {...this.props}>
        <Container>
          {awards.map(({ hideMobile, ...award }, i) => (
            <Wrapper key={i} hideMobile={hideMobile}>
              <Award {...award} />
            </Wrapper>
          ))}
        </Container>
      </SectionWrapper>
    );
  }
}

export const query = graphql`
  fragment AwardsSection on PrismicSectionBodyAwards {
    items {
      award {
        document {
          data {
            organization
            description {
              text
            }
            logo {
              url
            }
            link {
              url
            }
          }
        }
      }
    }
  }
`;

export default Awards;
