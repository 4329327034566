import styled from 'styled-components';
import { bodyGray, fontSizeXs, openSans, paddingMd } from 'style/constants';

const P2 = styled.p`
  color: ${bodyGray};
  font-size: ${fontSizeXs};
  font-family: ${openSans};
  padding-bottom: ${paddingMd};
`;

export default P2;
