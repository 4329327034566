import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'style';
import { tabletWidth, transitionSimpleXtraFast } from 'style/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;

  a,
  img {
    max-width: 200px;

    @media (max-width: ${tabletWidth}) {
      max-width: 175px;
    }
  }

  img {
    opacity: 0.6;
    transition: ${transitionSimpleXtraFast};
    backface-visibility: hidden;
  }

  a {
    outline: none;

    &:hover,
    &:focus {
      img {
        opacity: 1;
      }
    }
  }
`;

const Award = ({ organization, description, logo, link }) => {
  const title = organization + (description ? ` | ${description}` : '');
  const Wrapper = link ? Link : Fragment;
  const linkProps = link
    ? {
        to: link,
        newTab: true,
        title,
      }
    : {};
  return (
    <Container>
      <Wrapper {...linkProps}>
        <img src={logo} alt={title} />
      </Wrapper>
    </Container>
  );
};

Award.propTypes = {
  description: PropTypes.string,
  logo: PropTypes.string,
  link: PropTypes.string,
  organization: PropTypes.string,
};

export default Award;
