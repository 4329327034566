import React, { useState, useEffect } from "react"
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Label, Link, Link2, Logo, P, P2 } from 'style';

import {
  backgroundGray,
  bodyGray,
  blueActive,
  blueGrayDark,
  blueNavy,
  desktopWidth,
  mobileHdWidth,
  paddingSm,
  paddingMd,
  paddingLg,
  paddingXl,
  paddingXxl,
  paddingWrapperSm,
  paddingWrapperMd,
  paddingWrapperLg,
  tabletWidth,
  transitionSimpleXtraFast,
  greenActive,
  greenDark,
  redActive,
  redDark,
  fontSizeSm,
  openSans,
} from 'style/constants';
import Heart from 'static/img/heart.svg';
import { formatPhone } from 'utils';

const mobileBreak = '680px';

const Chip = styled.div`
white-space: nowrap;
  padding: ${paddingMd} ${paddingLg};
  color: ${greenDark};
  background-color: ${greenActive};
  font-size: ${fontSizeSm};
  line-height: ${fontSizeSm};
  font-family: ${openSans};
  border-radius: 30px;
`;

const Container = styled.footer.attrs({
  role: 'contentinfo',
})
  `
  position: relative;
  padding: ${paddingWrapperLg} ${paddingWrapperMd} ${paddingWrapperMd};
  box-sizing: border-box;
  background-color: ${backgroundGray};
  display: block;

  &::after {
    content: '';
    background-color: ${backgroundGray};
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    transform: translateY(200px);
    z-index: -1;
  }

  @media (max-width: ${tabletWidth}) {
    padding-top: ${paddingWrapperMd};
    padding-bottom: ${paddingWrapperSm};
  }

  @media (max-width: ${mobileHdWidth}) {
    padding-left: ${paddingWrapperSm};
    padding-right: ${paddingWrapperSm};
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${desktopWidth};
  box-sizing: border-box;
`;

const TopInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${paddingXl};

  @media (max-width: ${mobileBreak}) {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: ${paddingXl};
  }
`;

const MainLinks = styled.div`
display: flex;
flex-direction: row
  justify-content: flex-end;
  align-items: center;
  a {
    margin-left: ${paddingXxl};
    font-weight: 600;
    color: ${blueNavy};
    outline: none;

    &:hover,
    &:focus {
      text-decoration-color: ${blueNavy};
      text-decoration: underline;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  @media (max-width: ${mobileBreak}) {
    padding-top: ${paddingXxl};
  }
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${mobileBreak}) {
    padding-bottom: 0;
  }
  a {
    margin-right: ${paddingXl};
    border-radius: 50%;
    transition: ${transitionSimpleXtraFast};
    box-shadow: 0 0 0 0px ${blueGrayDark};
    width: 32px;
    outline: none;

    &:last-child {
      margin-right: 0px;
    }

    &:focus {
      box-shadow: 0 0 0 3px ${blueGrayDark};
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const BottomInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: ${paddingXl};

  @media (max-width: ${mobileBreak}) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: ${paddingXl};
  }
`;
const MiddleInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 50px;
  margin-bottom: 30px;

  @media (max-width: ${mobileBreak}) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;
  }
`;

const InfoParent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  margin-left: 20%;

  @media (max-width: ${tabletWidth}) {
    order: 1;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10%;
  }

  @media (max-width: ${mobileBreak}) {
    order: 1;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
  }
`;

const FooterSubtext = styled.div`
  max-width: 300px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: ${mobileBreak}) {
    order: 2;
    padding-left: 0;
    padding-top: ${paddingMd};

    padding-bottom: ${paddingMd};
  }
  &-name {
    display: flex;
    flex-direction: row;

    span {
      padding-right: $padding-xs;
      padding-bottom: $padding-xs;
      font-weight: 600;
    }
  }
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${paddingLg};

  @media (max-width: ${tabletWidth}) {
    padding-bottom: ${paddingXxl};
  }
  @media (max-width: ${mobileBreak}) {
    order: 1;
    padding-left: 0;
    padding-bottom: ${paddingXxl};
  }

  a {
    padding-top: ${paddingSm};
    outline: none;

    &:hover,
    &:focus {
      text-decoration-color: ${bodyGray};
      text-decoration: underline;
    }
  }
`;

const HeartIcon = styled(Heart)
  `
  width: 16px;
  height: 16px;
  color: ${blueActive};
  transform: translateY(1px);
`;

const StyledLabel = styled(Label)
  `
  margin-bottom: 0.75em;
`;

function status(online) {
  const Chip = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${online <= 1 ? (blueNavy) : redDark};
  background-color: ${online <= 1 ? 'transparent' : redActive};
  font-family: ${openSans};
  border-radius: 30px;
`;
  const GreenText = styled.div`
  padding-bottom: 3px;
  color: #68d391;
  margin-left: 5px;
  font-size: 1.5rem;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  line-height: 1.5;
`;
  return online <= 1 ? (online == 0 ? null : <Chip><Link2 to='https://status.wunderite.com/'>Status</Link2><GreenText>•</GreenText></Chip>) : <Chip style={{ padding: paddingMd }}><Link2 style={{ color: redDark }} to='https://status.wunderite.com/'>Status: Offline</Link2></Chip>
}

const Footer = () => (<
  StaticQuery query={graphql`
      query FooterQuery {
        footer: prismicFooter {
          data {
            copyright {
              text
            }
            contact_email
            contact_phone
            main_links {
              link_text
              link_href {
                url
              }
            }
            social_links {
              platform
              logo {
                url
              }
              link {
                url
              }
            }
          }
        }
      }
    ` }
  render={({ footer }) => {
    const {
      copyright,
      contact_email,
      contact_phone,
      main_links,
      social_links,
    } = footer.data;
    const year = new Date().getFullYear();
    const copyright_text = `© ${year} Wunderite, Inc. All rights reserved.`;

    const [online, setOnline] = useState(0)
    useEffect(() => {
      fetch("https://app.wunderite.com/api/custom/ohdear-status", {
        headers: {
          "Access-Control-Allow-Origin": "*", // Required for CORS support to work
          "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS 
          Accept: "application/json",
          Authorization: `Bearer ${process.env.OHDEAR_ACCESS_TOKEN}`,
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
          console.log('uptime: ' + resultData.data[1].checks[0].latest_run_result)
          let state = resultData.data[1].checks[0].latest_run_result == 'succeeded' ? 1 : 2
          setOnline(state)
        })
        .catch(() => console.log("Can’t access https://ohdear.app/api/sites response. Blocked by browser?"))
    }, [])

    return (
      <Container>
        <Wrapper>
          <TopInfo >
            <Logo />
            <MainLinks > {main_links.map((link, i) => (
              <Link2 to={link.link_href.url} key={i} > {link.link_text} </Link2>))}
              <div>{status(online)}</div>
            </MainLinks>
          </TopInfo>
          <MiddleInfo>
            <FooterSubtext>
              <StyledLabel > Wunderite(wunder• ite) </StyledLabel>
              <P2>Portmanteau of the German "wunder" (miracle), and "underwrite", the act of supporting a voyage by physically signing your name under a ship 's manifest </P2>
            </FooterSubtext>
            <InfoParent>
              <InfoColumn>
                <StyledLabel> Community </StyledLabel>
                <SocialLinks> {social_links.map(({ link, logo, platform }, i) => (
                  <Link2 to={link.url} title={platform} newTab key={i}>
                    {platform}
                  </Link2>))}
                </SocialLinks>
              </InfoColumn>
              <InfoColumn>
                <StyledLabel> Company </StyledLabel>
                <Link2 to="/about/"> About </Link2>
                <Link2 to="/press/"> Press </Link2>
                <Link2 to="/terms/"> Terms </Link2>
                {/* <Link2 to="/privacy/"> Privacy </Link2>
                <Link2 to="/terms/"> Terms </Link2> */}
              </InfoColumn>
              <InfoColumn>
                <StyledLabel> Contact </StyledLabel> {contact_email && (
                  <Link2 to={`mailto:${contact_email}`}> {contact_email} </Link2>)} {contact_phone && (< Link2 to={`tel:${contact_phone}`}> {formatPhone(contact_phone)} </Link2>)}
              </InfoColumn>
            </InfoParent>
          </MiddleInfo>
          <BottomInfo>
            <P2>Made with <HeartIcon /> in Boston</P2>
            <P2> {copyright_text} </P2>
          </BottomInfo>
          <noscript>
            <img src={"https://ws.zoominfo.com/pixel/61254955e10cf9001537fbd1"} width={1} height={1} style={{ display: 'none' }} />
          </noscript>
        </Wrapper>
      </Container>
    );
  }
  } />);
export default Footer;