import React from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Footer, Nav } from 'components';

const App = styled.div`
  display: block;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden;
  display: block;

  ${props =>
    props.scrollDisabled &&
    css`
      position: fixed;
      overflow: hidden;
      width: 100%;
    `};
`;

const Main = styled.main.attrs({
  role: 'main',
})`
  flex: 1;
  padding-top: ${props => props.padNav}px;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.2s ease-out;
  display: block;
`;

const Layout = ({ children, height, location, scrollDisabled }) => (
  <App scrollDisabled={scrollDisabled}>
    <Nav home={location.pathname === '/'} />
    <Main padNav={height} show={height > 0}>
      {children}
    </Main>
    <Footer />
  </App>
);

Layout.propTypes = {
  height: PropTypes.number.isRequired,
  scrollDisabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = state => state.nav;

export default connect(
  mapStateToProps,
  {}
)(Layout);
