import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import Reset from 'style/reset';

const Head = ({ title, image, description }) => (
  <StaticQuery
    query={graphql`
      query SEOQuery {
        seo: prismicSeo {
          data {
            meta_title {
              text
            }
            meta_description {
              text
            }
            meta_image {
              url
            }
            meta_url {
              url
            }
            meta_keywords {
              keyword
            }
          }
        }
      }
    `}
    render={({ seo }) => {
      const seoMeta = {
        title: title ? title : seo.data.meta_title.text,
        description: description || seo.data.meta_description.text,
        image: image || seo.data.meta_image.url,
        url: seo.data.meta_url.url,
      };
      return (
        <Fragment>
          <Reset />
          <Helmet>
            <html lang="en" />
            <title>{title || seoMeta.title}</title>
            <meta name="description" content={seoMeta.description} />
            <meta property="og:type" content="website" />
            <meta property="twitter:card" content="summary_large_image" />
            {['og', 'twitter'].map(pre =>
              Object.keys(seoMeta).map((property, i) => (
                <meta
                  key={i}
                  property={`${pre}:${property}`}
                  content={seoMeta[property]}
                />
              ))
            )}
            <script>
              {`
                (function () {
                  var zi = document.createElement('script');
                  zi.type = 'text/javascript';
                  zi.async = true;
                  zi.referrerPolicy = 'unsafe-url';
                  zi.src = 'https://ws.zoominfo.com/pixel/61254955e10cf9001537fbd1';
                  var s = document.getElementsByTagName('script')[0];
                  s.parentNode.insertBefore(zi, s);
                })();
              `}
            </script>
          </Helmet>
        </Fragment>
      );
    }}
  />
);

export default Head;
