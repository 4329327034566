import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Container from './BaseFeature';
import {
  blueGrayLight,
  blueGrayActive,
  blueActive,
  borderRadiusLg,
  cardShadow,
  grayDark,
  white,
} from 'style/constants';
import Large404Content from 'static/img/cards/404/large.svg';
import Medium404Content from 'static/img/cards/404/medium.svg';
import Small404Content from 'static/img/cards/404/small.svg';

const RightLines = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, -${parallax}px, 0px)`,
    transform: `translate3d(0px, -${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  right: 20%;
  top: 22%;
  width: 22%;
  height: 2%;
  border-radius: 10px;
  background: #c5cae8;
  background-clip: padding-box;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  z-index: 12;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -15%;
    transform: translateY(200%);
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background: #c5cae8;
  }
`;

const Small404Card = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax}px, 0px) rotate(-2deg)`,
    transform: `translate3d(0px, ${parallax}px, 0px) rotate(-2deg)`,
  },
}))`
  position: absolute;
  top: 21%;
  left: 21%;
  width: 26%;
  border-radius: ${borderRadiusLg};
  border: 1px solid ${grayDark};
  box-sizing: border-box;
  background-color: ${white};
  box-shadow: ${cardShadow};
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  overflow: hidden;

  svg {
    width: 100%;
    height: auto;
    margin-bottom: -4px;
  }
`;

const Medium404Card = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax}px, 0px) rotate(5deg)`,
    transform: `translate3d(0px, ${parallax}px, 0px) rotate(5deg)`,
  },
}))`
  position: absolute;
  top: 35%;
  right: -7%;
  width: 38%;
  border-radius: ${borderRadiusLg};
  border: 1px solid ${grayDark};
  box-sizing: border-box;
  background-color: ${white};
  box-shadow: ${cardShadow};
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  overflow: hidden;

  svg {
    width: 100%;
    height: auto;
    margin-bottom: -4px;
  }
`;

const Large404Card = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax}px, 0px) rotate(-7deg)`,
    transform: `translate3d(0px, ${parallax}px, 0px) rotate(-7deg)`,
  },
}))`
  position: absolute;
  top: 45%;
  left: -7%;
  width: 62%;
  border-radius: ${borderRadiusLg};
  border: 1px solid ${grayDark};
  box-sizing: border-box;
  background-color: ${white};
  box-shadow: ${cardShadow};
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  overflow: hidden;

  svg {
    width: 100%;
    height: auto;
    margin-bottom: -4px;
  }
`;

const TopLine = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, -${parallax}px, 0px)`,
    transform: `translate3d(0px, -${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  left: -3%;
  top: 52%;
  width: 20%;
  height: 2.5%;
  border-radius: 10px;
  background: linear-gradient(to left, #969bc5, #dcdff2);
  background-clip: padding-box;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  z-index: 12;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-175%);
    height: 100%;
    width: 13%;
    border-radius: 10px;
    background: #dcdff2;
  }
`;

const BottomLines = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, -${parallax}px, 0px)`,
    transform: `translate3d(0px, -${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  right: -6%;
  bottom: 26%;
  width: 35%;
  height: 2.5%;
  border-radius: 10px;
  background: linear-gradient(to left, #969bc5, #c5cae8);
  background-clip: padding-box;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  z-index: 12;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 15%;
    transform: translateY(200%);
    height: 100%;
    width: 70%;
    border-radius: 10px;
    background: #c5cae8;
  }
`;

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: {
        top: blueGrayLight,
        bottom: blueGrayActive,
      },
      initialParallax: {
        upCards: {
          depth: -0.07,
          height: 0,
          initialHeight: 0,
          endHeight: 50,
        },
        downCards: {
          depth: 0.05,
          height: 0,
          initialHeight: 0,
          endHeight: 50,
        },
        topLine: {
          depth: 0.1,
          height: 0,
          initialHeight: 0,
          endHeight: 50,
        },
      },
    };

    this.renderBackground = this.renderBackground.bind(this);
    this.renderForeground = this.renderForeground.bind(this);
  }

  renderBackground() {
    return (
      <Fragment>
        <RightLines />
      </Fragment>
    );
  }

  renderForeground(parallax) {
    const { show } = this.props;
    return (
      <Fragment>
        <Small404Card parallax={parallax.upCards.height} show={show}>
          <Small404Content />
        </Small404Card>
        <Medium404Card parallax={parallax.upCards.height} show={show}>
          <Medium404Content />
        </Medium404Card>
        <TopLine parallax={parallax.topLine.height} />
        <Large404Card parallax={parallax.downCards.height} show={show}>
          <Large404Content />
        </Large404Card>
        <BottomLines />
      </Fragment>
    );
  }

  render() {
    return (
      <Container
        ref={e => (this.container = e)}
        theme={this.state.theme}
        show
        parallax={this.state.initialParallax}
        renderBackground={this.renderBackground}
        renderForeground={this.renderForeground}
      />
    );
  }
}

export default NotFound;
