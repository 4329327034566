import { SET_NAV_HEIGHT, TOGGLE_DISABLE_SCROLL } from 'actions/types';

const initialState = {
  height: 0,
  scrollDisabled: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NAV_HEIGHT:
      return {
        ...state,
        height: action.payload,
      };
    case TOGGLE_DISABLE_SCROLL:
      return {
        ...state,
        scrollDisabled: action.payload,
      };
    default:
      return state;
  }
};
