import React from 'react';
import { Layout } from 'components';
import { wrapRootElement } from 'utils';

export const wrapPageElement = ({ props, element }) => {
  return <Layout {...props}>{element}</Layout>;
};

const { registerLinkResolver } = require("gatsby-source-prismic-graphql")
const { linkResolver } = require("./src/utils/linkResolver")
registerLinkResolver(linkResolver)

export { wrapRootElement };

