import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import {
  FormsIllustration,
  ProfilesIllustration,
  SectionWrapper,
  TablesIllustration,
} from 'components';
import { H4, P } from 'style';
import {
  blueNavy,
  lightGray,
  mobileHdWidth,
  paddingSm,
  paddingLg,
  paddingXl,
  paddingXxl,
  paddingWrapperMd,
  paddingWrapperXl,
} from 'style/constants';
import Arrow from 'static/img/arrow.svg';

const ILLUSTRATIONS = {
  forms: FormsIllustration,
  profiles: ProfilesIllustration,
  tables: TablesIllustration,
};

const tabletBreak = '945px';
const mobileBreak = '750px';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${paddingWrapperXl};
  box-sizing: border-box;

  @media (max-width: ${mobileBreak}) {
    display: none;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${paddingWrapperMd};
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  padding-top: 8%;

  @media (max-width: ${tabletBreak}) {
    padding-top: 2%;
  }
`;

const Selectors = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-bottom: ${paddingWrapperMd};

  @media (max-width: ${tabletBreak}) {
    padding-left: ${paddingWrapperMd};
  }
`;

const Selector = styled.button`
  padding: 0;
  border: none;
  outline: none;
  background: none;
  text-align: left;
  margin-bottom: ${paddingXxl};
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: ${tabletBreak}) {
    margin-bottom: 25px;
  }
`;

const SelectorTitle = styled(H4)`
  color: ${props => (props.selected ? blueNavy : lightGray)};
  transition: 0.15s ease-out;

  @media (max-width: ${tabletBreak}) {
    font-size: 22px;
  }
`;

const Description = styled.div`
  padding-left: 50px;
  max-width: 450px;
  position: relative;

  @media (max-width: ${tabletBreak}) {
    padding-left: ${paddingWrapperMd};
  }

  p {
    line-height: 1.5;
    position: absolute;
    top: 0;
    left: 50px;
    opacity: 0;
    transition: 0.15s ease-out;
    pointer-events: none;

    @media (max-width: ${tabletBreak}) {
      left: ${paddingWrapperMd};
    }

    &:nth-child(${props => props.selIndex + 1}) {
      opacity: 1;
      position: relative;
      top: auto;
      left: auto;
      pointer-events: all;
    }
  }
`;

const ArrowIcon = styled(Arrow).attrs(({ position }) => ({
  style: {
    transform: `translateY(${position}px)`,
  },
}))`
  position: absolute;
  top: 0px;
  left: 0;
  transition: 0.25s cubic-bezier(0.56, -0.19, 0.05, 1);
  width: 24px;
  height: 24px;

  @media (max-width: ${tabletBreak}) {
    top: 1px;
    width: 20px;
    height: 20px;
  }
`;

const RightWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 60px;
  flex: 1;
  flex-shrink: 0;

  @media (max-width: ${tabletBreak}) {
    padding-right: 35px;
  }
`;

const IllustrationContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;

  @media (max-width: ${mobileBreak}) {
    margin: 0 auto;
    transform: scale(0.8);
    max-width: 400px;
  }
`;

const IllustrationWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: 50%;

  @media (max-width: ${tabletBreak}) {
    transform: scale(1.05);
  }
`;

const MobileWrapper = styled.div`
  display: none;
  flex-direction: column;
  padding-top: ${paddingXl};

  @media (max-width: ${mobileBreak}) {
    display: flex;
  }
`;

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${paddingXxl};

  &:last-child {
    margin-bottom: 0;
  }

  h4 {
    padding-bottom: ${paddingSm};
    line-height: 1.2;
  }

  h4,
  p {
    max-width: 500px;
    text-align: center;
  }

  @media (max-width: ${mobileHdWidth}) {
    align-items: flex-start;

    h4,
    p {
      text-align: left;
    }
  }
`;

class Features extends Component {
  constructor(props) {
    super(props);
    this.changeTimeout = null;
    this.selectors = [];
    this.state = {
      features: this.parseFeatures(props.features),
      selIndex: 0,
      showIllustration: true,
      arrowPosition: 0,
    };
    this.parseFeatures = this.parseFeatures.bind(this);
    this.updateArrowPosition = this.updateArrowPosition.bind(this);
    this.updateIndex = this.updateIndex.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.updateArrowPosition();
    window.addEventListener('resize', this.updateArrowPosition);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updateArrowPosition);
  }

  componentWillReceiveProps(newProps, oldProps) {
    this.setState({
      features: this.parseFeatures(newProps.features),
    });
  }

  parseFeatures(features) {
    return features.map(({ title, description, illustration }) => {
      return {
        title: title.text,
        description: description.text,
        illustration,
      };
    });
  }

  updateArrowPosition() {
    const { selIndex } = this.state;
    if (this.desktopWrapper) {
      this.setState({
        arrowPosition: this.selectors[selIndex].offsetTop,
      });
    }
  }

  updateIndex(selIndex) {
    if (selIndex !== this.state.selIndex) {
      if (this.changeTimeout) {
        clearTimeout(this.changeTimeout);
      }
      this.setState(
        {
          showIllustration: false,
          arrowPosition: this.selectors[selIndex].offsetTop,
        },
        () => {
          this.changeTimeout = setTimeout(() => {
            this.setState(
              {
                selIndex,
              },
              () => {
                this.changeTimeout = setTimeout(() => {
                  this.setState({
                    showIllustration: true,
                  });
                }, 100);
              }
            );
          }, 200);
        }
      );
    }
  }

  setState(newState, callback) {
    if (this.mounted) {
      super.setState(newState, callback);
    }
  }

  render() {
    const { arrowPosition, features, selIndex, showIllustration } = this.state;
    return (
      <SectionWrapper {...this.props}>
        <Container ref={e => (this.container = e)}>
          <DesktopWrapper ref={e => (this.desktopWrapper = e)}>
            <LeftWrapper>
              <Selectors>
                <ArrowIcon position={arrowPosition} />
                {features.map((feature, i) => (
                  <Selector
                    key={i}
                    ref={e => (this.selectors[i] = e)}
                    onClick={() => this.updateIndex(i)}
                  >
                    <SelectorTitle selected={selIndex === i}>
                      {feature.title}
                    </SelectorTitle>
                  </Selector>
                ))}
              </Selectors>
              <Description selIndex={selIndex}>
                {features.map((feature, i) => (
                  <P key={i}>{feature.description}</P>
                ))}
              </Description>
            </LeftWrapper>
            <RightWrapper>
              <IllustrationContainer>
                <IllustrationWrapper>
                  {features.map((feature, i) => {
                    let Illustration = ILLUSTRATIONS[feature.illustration];
                    return (
                      <Illustration
                        key={i}
                        show={showIllustration && i === selIndex}
                        hide={i !== selIndex}
                      />
                    );
                  })}
                </IllustrationWrapper>
              </IllustrationContainer>
            </RightWrapper>
          </DesktopWrapper>
          <MobileWrapper ref={e => (this.mobileWrapper = e)}>
            {features.map((feature, i) => {
              let Illustration = ILLUSTRATIONS[feature.illustration];
              return (
                <FeatureWrapper key={i}>
                  <IllustrationContainer>
                    <Illustration show mobile />
                  </IllustrationContainer>
                  <H4>{feature.title}</H4>
                  <P>{feature.description}</P>
                </FeatureWrapper>
              );
            })}
          </MobileWrapper>
        </Container>
      </SectionWrapper>
    );
  }
}

export const query = graphql`
  fragment FeaturesSection on PrismicSectionBodyFeatures {
    items {
      feature {
        document {
          data {
            title {
              text
            }
            description {
              text
            }
            illustration
          }
        }
      }
    }
  }
`;

export default Features;
