/**
 * @param container the container element being checked
 * @param minTriggerHeight a value from 0 – 1 that represents the percentage the elements needs to be visible to trigger parallax effect
 * @param parallax an object of parallax effects
 */
export default (element, minTriggerHeight, parallax, aboveTop) => {
  if (!element) {
    return null;
  }
  let {
    top: elScrollPos,
    bottom: elBottomPos,
  } = element.getBoundingClientRect();
  if (elBottomPos <= 0) {
    return null;
  }
  let scrollTop = window.pageYOffset;
  if (!aboveTop) {
    const triggerHeight = element.offsetHeight * minTriggerHeight;
    const currScrollPos = Math.max(0, window.innerHeight - elScrollPos);
    scrollTop = currScrollPos - triggerHeight;
  }
  const newParallax = parallax;
  Object.keys(newParallax).map(key => {
    const { depth, initialHeight, endHeight } = newParallax[key];
    if (scrollTop > 0) {
      const height = initialHeight + scrollTop * depth;
      newParallax[key].height =
        endHeight > 0
          ? Math.min(height, endHeight)
          : Math.max(height, endHeight);
    } else {
      newParallax[key].height = initialHeight;
    }
  });
  return newParallax;
};
