import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Container from './BaseFeature';
import {
  borderRadiusLg,
  blueGrayActive,
  cardShadow,
  grayDark,
  white,
} from 'style/constants';
import SuggestInputContent from 'static/img/cards/suggest-input.svg';
import tableContentSvg from 'static/img/cards/table/content.svg';
import TableHeader from 'static/img/cards/table/header.svg';

const TopLeftLines = styled.div`
  position: absolute;
  top: 30%;
  left: 18%;
  width: 22%;
  height: 2.5%;
  border-radius: 10px;
  background: linear-gradient(to left, #c96aed, #832abb);
  background-clip: padding-box;
  opacity: 0.5;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(225%);
    height: 100%;
    width: 150%;
    border-radius: 10px;
    background: #c96aed;
  }
`;

const BottomLines = styled.div`
  position: absolute;
  bottom: 32%;
  left: 32%;
  width: 22%;
  height: 2.5%;
  border-radius: 10px;
  background: linear-gradient(to right, #c96aed, #832abb);
  background-clip: padding-box;
  opacity: 0.5;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 30%;
    transform: translateY(225%);
    height: 100%;
    width: 150%;
    border-radius: 10px;
    background: #c96aed;
  }
`;

const SuggestInput = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax}px, 0px)`,
    transform: `translate3d(0px, ${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  bottom: 17%;
  left: -6%;
  width: 60%;
  border-radius: ${borderRadiusLg};
  border: 1px solid ${blueGrayActive};
  box-sizing: border-box;
  background-color: ${white};
  box-shadow: ${cardShadow};
  overflow: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;

  svg {
    width: 100%;
    height: auto;
    margin-bottom: -4px;
  }
`;

const TableCard = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax}px, 0px)`,
    transform: `translate3d(0px, ${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 15%;
  left: 46%;
  width: 56%;
  height: 60%;
  border-radius: ${borderRadiusLg};
  border: 1px solid ${grayDark};
  box-sizing: border-box;
  background-color: ${white};
  box-shadow: ${cardShadow};
  overflow: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
`;

const TableHeaderWrapper = styled.div`
  z-index: 10;

  svg {
    width: 100%;
    height: auto;
    margin-bottom: -4px;
  }
`;

const TableContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  z-index: 9;
`;

const TableContent = styled(tableContentSvg).attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, -${parallax}px, 0px)`,
    transform: `translate3d(0px, -${parallax}px, 0px)`,
  },
}))`
  width: 100%;
  height: auto;
  margin-bottom: -4px;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
`;

const MiddleLine = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, -${parallax}px, 0px)`,
    transform: `translate3d(0px, -${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  right: -5%;
  bottom: 28%;
  width: 20%;
  height: 3%;
  border-radius: 10px;
  background: linear-gradient(to left, #f1d9ff, #dfb1fd);
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(175%);
    height: 100%;
    width: 15%;
    border-radius: 10px;
    background: #dfb1fd;
  }
`;

class Tables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: {
        top: '#DF91FD',
        bottom: '#E7C3FE',
      },
      initialParallax: {
        suggestInput: {
          depth: -0.15,
          height: -10,
          initialHeight: -10,
          endHeight: 120,
        },
        tableCard: {
          depth: 0.05,
          height: 10,
          initialHeight: 10,
          endHeight: 50,
        },
        tableContent: {
          depth: 0.1,
          height: 0,
          initialHeight: 0,
          endHeight: 130,
        },
        middleLine: {
          depth: -0.1,
          height: -40,
          initialHeight: -40,
          endHeight: 50,
        },
      },
    };

    this.renderBackground = this.renderBackground.bind(this);
    this.renderForeground = this.renderForeground.bind(this);
  }

  renderBackground() {
    return (
      <Fragment>
        <TopLeftLines />
        <BottomLines />
      </Fragment>
    );
  }

  renderForeground(parallax) {
    const { show } = this.props;
    return (
      <Fragment>
        <SuggestInput parallax={parallax.suggestInput.height} show={show}>
          <SuggestInputContent />
        </SuggestInput>
        <MiddleLine parallax={parallax.middleLine.height} />
        <TableCard parallax={parallax.tableCard.height} show={show}>
          <TableHeaderWrapper>
            <TableHeader />
          </TableHeaderWrapper>
          <TableContentWrapper>
            <TableContent parallax={parallax.tableContent.height} />
          </TableContentWrapper>
        </TableCard>
      </Fragment>
    );
  }

  render() {
    return (
      <Container
        ref={e => (this.container = e)}
        theme={this.state.theme}
        mobile={this.props.mobile}
        hide={this.props.hide}
        show={this.props.show}
        parallax={this.state.initialParallax}
        renderBackground={this.renderBackground}
        renderForeground={this.renderForeground}
      />
    );
  }
}

export default Tables;
