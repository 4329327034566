import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Container from './BaseFeature';
import {
  blueGrayLight,
  blueGrayActive,
  borderRadiusLg,
  cardShadow,
  grayDark,
  white,
} from 'style/constants';
import SicCodeInputContent from 'static/img/cards/sic-code.svg';
import NaicsCodeInputContent from 'static/img/cards/naics-code.svg';
import WorkersCompContent from 'static/img/cards/work-comp.svg';

const TopLines = styled.div`
  position: absolute;
  top: 16.5%;
  left: 55%;
  width: 22%;
  height: 2.5%;
  border-radius: 10px;
  background: #a0a4cb;
  opacity: 0.5;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(225%);
    height: 100%;
    width: 150%;
    border-radius: 10px;
    background: #a0a4cb;
  }
`;

const LeftLines = styled.div`
  position: absolute;
  top: 54%;
  left: -8%;
  width: 30%;
  height: 2.5%;
  border-radius: 10px;
  background: linear-gradient(to left, #969bc5, #dcdff2);
  background-clip: padding-box;
  z-index: 9;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 20%;
    transform: translateY(200%);
    height: 100%;
    width: 69%;
    border-radius: 10px;
    background: #b7bcdd;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -5%;
    transform: translateY(200%);
    height: 100%;
    width: 9%;
    border-radius: 10px;
    background: #b7bcdd;
  }
`;

const SicCodeInput = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax}px, 0px)`,
    transform: `translate3d(0px, ${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  top: 18%;
  left: 4%;
  width: 67%;
  border-radius: ${borderRadiusLg};
  border: 1px solid ${blueGrayActive};
  box-sizing: border-box;
  background-color: ${white};
  box-shadow: ${cardShadow};
  overflow: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  height: 4rem;

  svg {
    width: 100%;
    height: auto;
    margin-bottom: -4px;
  }
`;

const NaicsCodeInput = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax}px, 0px)`,
    transform: `translate3d(0px, ${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  top: 34%;
  left: 4%;
  width: 67%;
  border-radius: ${borderRadiusLg};
  border: 1px solid ${blueGrayActive};
  box-sizing: border-box;
  background-color: ${white};
  box-shadow: ${cardShadow};
  overflow: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  z-index: 10;
  height: 4rem;

  svg {
    width: 100%;
    height: auto;
    margin-bottom: -4px;
  }
`;

const RiskDataCard = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax}px, 0px)`,
    transform: `translate3d(0px, ${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: -1%;
  right: 3%;
  width: 56%;
  border-radius: ${borderRadiusLg};
  border: 1px solid ${grayDark};
  box-sizing: border-box;
  background-color: ${white};
  box-shadow: ${cardShadow};
  overflow: hidden;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  z-index: 11;

  svg {
    width: 100%;
    height: auto;
    margin-bottom: -4px;
  }
`;

const BottomLine = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, -${parallax}px, 0px)`,
    transform: `translate3d(0px, -${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  right: 0%;
  bottom: 20%;
  width: 17%;
  height: 3%;
  border-radius: 10px;
  background: linear-gradient(to right, #969bc5, #dcdff2);
  background-clip: padding-box;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  z-index: 12;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(175%);
    height: 100%;
    width: 17%;
    border-radius: 10px;
    background: #dcdff2;
  }
`;

class Forms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: {
        top: blueGrayLight,
        bottom: blueGrayActive,
      },
      initialParallax: {
        sicCode: {
          depth: 0.03,
          height: -10,
          initialHeight: -10,
          endHeight: 120,
        },
        naicsCode: {
          depth: 0.07,
          height: -10,
          initialHeight: -10,
          endHeight: 120,
        },
        rdCard: {
          depth: -0.05,
          height: 10,
          initialHeight: 10,
          endHeight: 50,
        },
        bottomLine: {
          depth: -0.1,
          height: -40,
          initialHeight: -40,
          endHeight: 50,
        },
      },
    };

    this.renderBackground = this.renderBackground.bind(this);
    this.renderForeground = this.renderForeground.bind(this);
  }

  renderBackground() {
    return (
      <Fragment>
        <TopLines />
      </Fragment>
    );
  }

  renderForeground(parallax) {
    const { show } = this.props;
    return (
      <Fragment>
        <SicCodeInput parallax={parallax.sicCode.height} show={show}>
          <SicCodeInputContent />
        </SicCodeInput>
        <NaicsCodeInput parallax={parallax.naicsCode.height} show={show}>
          <NaicsCodeInputContent />
        </NaicsCodeInput>
        <LeftLines />
        <BottomLine parallax={parallax.bottomLine.height} />
        <RiskDataCard parallax={parallax.rdCard.height} show={show}>
          <WorkersCompContent />
        </RiskDataCard>
      </Fragment>
    );
  }

  render() {
    return (
      <Container
        ref={e => (this.container = e)}
        theme={this.state.theme}
        mobile={this.props.mobile}
        hide={this.props.hide}
        show={this.props.show}
        parallax={this.state.initialParallax}
        renderBackground={this.renderBackground}
        renderForeground={this.renderForeground}
      />
    );
  }
}

export default Forms;
