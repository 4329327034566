import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { updateParallax } from 'utils';
import Wave from 'static/img/wave.svg';

const Container = styled.div.attrs(({ mobile }) => ({
  style: {
    position: mobile ? 'relative' : 'absolute',
    top: mobile ? 'auto' : '0px',
    left: mobile ? 'auto' : '0px',
    height: mobile ? 'auto' : '100%',
    paddingTop: mobile ? '100%' : '0%',
  },
}))`
  width: 100%;
  background-color: ${props => props.theme.top};
  border-radius: 50%;
  opacity: ${props => (props.hide ? 0 : 1)};
  pointer-events: ${props => (props.hide ? 'none' : 'all')};
  transition: 0.2s ease-out;
`;

const Background = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  mask-image: radial-gradient(white, black);
`;

const WaveWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateY(10%);
  width: 100%;
  height: 40%;

  &::after {
    content: '';
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    height: 200%;
    transform: translateY(100%);
    background-color: ${props => props.theme.bottom};
  }

  svg {
    color: ${props => props.theme.bottom};
  }
`;

const Foreground = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: scale(${props => (props.show ? 1 : 0.7)});
  opacity: ${props => (props.show ? 1 : 0)};
  transition: transform 0.2s ease-out, opacity 0.1s ease-out;
`;

class BaseFeature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parallax: props.parallax,
    };

    this.updateParallax = this.updateParallax.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    if (this.state.parallax) {
      window.requestAnimationFrame(this.updateParallax);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    window.cancelAnimationFrame(this.updateParallax);
  }

  updateParallax() {
    const triggerHeight =
      this.props.triggerHeight || (this.props.mobile ? 0.25 : 0.5);
    if (this.el) {
      const parallax = updateParallax(
        this.el,
        triggerHeight,
        this.state.parallax,
        this.props.aboveTop || false
      );
      if (parallax) {
        return this.setState(
          {
            parallax,
          },
          () => {
            window.requestAnimationFrame(this.updateParallax);
          }
        );
      }
    }
    window.requestAnimationFrame(this.updateParallax);
  }

  setState(newState, props) {
    if (this.mounted) {
      super.setState(newState, props);
    }
  }

  render() {
    const {
      hide,
      mobile,
      theme,
      renderBackground,
      renderForeground,
      show,
    } = this.props;
    return (
      <Container
        theme={theme}
        hide={hide}
        mobile={mobile}
        ref={e => (this.el = e)}
      >
        <Background>
          {/* <WaveWrapper theme={theme}>
            <Wave />
          </WaveWrapper> */}
          {renderBackground()}
        </Background>
        <Foreground show={show}>
          {renderForeground(this.state.parallax)}
        </Foreground>
      </Container>
    );
  }
}

BaseFeature.propTypes = {
  initialParallax: PropTypes.object,
  hide: PropTypes.bool,
  mobile: PropTypes.bool,
  renderBackground: PropTypes.func.isRequired,
  renderForeground: PropTypes.func.isRequired,
  show: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  aboveTop: PropTypes.bool,
  triggerHeight: PropTypes.number,
};

export default BaseFeature;
