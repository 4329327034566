import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Chip, H5, Icon, P } from 'style';
import { paddingMd, paddingLg } from 'style/constants';

const Wrapper = styled.div`
  display: inline-block;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 350px;

  h5 {
    padding-top: ${paddingLg};
    padding-bottom: ${paddingMd};
  }

  p {
    max-width: 700px;
    line-height: 1.5;
  }

  .chip {
    margin-top: ${paddingLg};
  }
`;

const Differentiator = ({ title, description, is_available_now, icon }) => {
  return (
    <Wrapper>
      <Icon name={icon} />
      <H5>{title}</H5>
      {description && <P>{description}</P>}
      {!is_available_now && <Chip>Coming soon</Chip>}
    </Wrapper>
  );
};

Differentiator.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.string,
  is_available_now: PropTypes.bool,
  title: PropTypes.string,
};

export default Differentiator;
