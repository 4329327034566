import styled from 'styled-components';
import { bodyGray, fontSizeMd, openSans, blueActive, montserrat, blueNavy } from 'style/constants';

const Typography = styled.div`
  color: ${bodyGray};
  font-size: ${fontSizeMd};
  line-height: 1.7;
  font-family: ${openSans};

  h2,
  h3 {
    margin: 0 0 .5em;
    font-family: ${montserrat};
    font-weight: 600;
    color: ${blueNavy};
  }

  p {
    margin: 0 0 1em;
  }

  ul,
  ol {
    padding-left: 1.5em;
    margin: 0 0 1em;
  }

  a {
    color: ${blueActive};

    &:hover {
      text-decoration: none;
    }
  }
`;

export default Typography