import React from 'react';
import styled, { css } from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { bodyGray, fontSizeMd, openSans } from 'style/constants';

const linkStyles = css`
  color: ${bodyGray};
  font-family: ${openSans};
  font-size: ${fontSizeMd};
  text-decoration: none;
`;

const Anchor = styled.a([linkStyles]);

const InternalLink = styled(GatsbyLink)([linkStyles]);

const Link = ({ children, to, newTab, ...props }) => {
  const isInternal = /^\/(?!\/)/.test(to);
  const isFile = /\.[0-9a-z]+$/i.test(to);
  const useInternalLink = isInternal && !isFile;

  const Component = useInternalLink ? InternalLink : Anchor;
  props = Object.assign(props, {
    [useInternalLink ? 'to' : 'href']: to,
    target: newTab && !useInternalLink ? '_blank' : '_self',
  });

  return <Component {...props}>{children}</Component>;
};

export default Link;
