import styled from 'styled-components';
import {
  blueNavy,
  labelGray,
} from 'style/constants';

const Blockquote = styled.blockquote`
  padding: 0 1.5em;
  margin: 0 0 1em;
  border-left: 3px solid ${blueNavy};
  font-size: .9em;
  font-style: italic;
  color: ${labelGray};
`;

export default Blockquote;