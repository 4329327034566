import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Container from './BaseFeature';
import {
  blueActive,
  blueDark,
  borderRadiusLg,
  cardShadow,
  grayDark,
  white,
} from 'style/constants';
import RiskProfileCardContent from 'static/img/cards/risk-profile.svg';
import RiskDataCardContent from 'static/img/cards/risk-data.svg';

const BottomLeftLine = styled.div`
  position: absolute;
  bottom: 18%;
  left: 0;
  width: 30%;
  height: 2.5%;
  border-radius: 10px;
  background-color: ${white};
  opacity: 0.33;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(200%);
    height: 100%;
    width: 8%;
    border-radius: 10px;
    background-color: ${white};
  }
`;

const MiddleLines = styled.div`
  position: absolute;
  top: 42%;
  left: 25%;
  width: 22%;
  height: 2.5%;
  border-radius: 10px;
  background: linear-gradient(to left, #b8c1fd, #828ef9);
  background-clip: padding-box;
  opacity: 0.5;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(225%);
    height: 100%;
    width: 150%;
    border-radius: 10px;
    background: #828ef9;
  }
`;

const RiskDataCard = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax}px, 0px)`,
    transform: `translate3d(0px, ${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  top: 15%;
  left: 1%;
  width: 60%;
  border-radius: ${borderRadiusLg};
  border: 1px solid ${grayDark};
  box-sizing: border-box;
  background-color: ${white};
  box-shadow: ${cardShadow};
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;

  svg {
    width: 100%;
    height: auto;
    margin-bottom: -4px;
  }
`;

const RiskProfileCard = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax}px, 0px)`,
    transform: `translate3d(0px, ${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  top: 40%;
  left: 50%;
  width: 60%;
  border-radius: ${borderRadiusLg};
  border: 1px solid ${grayDark};
  box-sizing: border-box;
  background-color: ${white};
  box-shadow: ${cardShadow};
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;

  svg {
    width: 100%;
    height: auto;
    margin-bottom: -4px;
  }
`;

const TopBlueLines = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, -${parallax}px, 0px)`,
    transform: `translate3d(0px, -${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  right: -17%;
  bottom: 28%;
  width: 30%;
  height: 2.5%;
  border-radius: 10px;
  background-color: ${blueActive};
  z-index: 10;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(200%);
    height: 100%;
    width: 8%;
    border-radius: 10px;
    background: #5c8ced;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 16%;
    transform: translateY(200%);
    height: 100%;
    width: 60%;
    border-radius: 10px;
    background: linear-gradient(to left, #5c8ced, ${blueActive});
    background-clip: padding-box;
  }
`;

class Profiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: {
        top: blueDark,
        bottom: blueActive,
      },
      initialParallax: {
        rdCard: {
          depth: -0.07,
          height: 15,
          initialHeight: 15,
          endHeight: 50,
        },
        rpCard: {
          depth: 0.05,
          height: -10,
          initialHeight: -10,
          endHeight: 50,
        },
        topBlueLines: {
          depth: 0.1,
          height: -40,
          initialHeight: -40,
          endHeight: 50,
        },
      },
    };

    this.renderBackground = this.renderBackground.bind(this);
    this.renderForeground = this.renderForeground.bind(this);
  }

  renderBackground() {
    return (
      <Fragment>
        <BottomLeftLine />
        <MiddleLines />
      </Fragment>
    );
  }

  renderForeground(parallax) {
    const { show } = this.props;
    return (
      <Fragment>
        <RiskDataCard parallax={parallax.rdCard.height} show={show}>
          <RiskDataCardContent />
        </RiskDataCard>
        <TopBlueLines parallax={parallax.topBlueLines.height} />
        <RiskProfileCard parallax={parallax.rpCard.height} show={show}>
          <RiskProfileCardContent />
        </RiskProfileCard>
      </Fragment>
    );
  }

  render() {
    return (
      <Container
        ref={e => (this.container = e)}
        theme={this.state.theme}
        mobile={this.props.mobile}
        hide={this.props.hide}
        show={this.props.show}
        parallax={this.state.initialParallax}
        renderBackground={this.renderBackground}
        renderForeground={this.renderForeground}
      />
    );
  }
}

export default Profiles;
