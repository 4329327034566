import styled from 'styled-components';
import {
  fontSizeSm,
  openSans,
  paddingMd,
  paddingLg,
  purpleActive,
  purpleDark,
} from 'style/constants';

const Chip = styled.div.attrs({
  className: 'chip',
})`
  padding: ${paddingMd} ${paddingLg};
  color: ${purpleDark};
  background-color: ${purpleActive};
  font-size: ${fontSizeSm};
  line-height: ${fontSizeSm};
  font-family: ${openSans};
  border-radius: 30px;
`;

export default Chip;
