import styled from 'styled-components';
import { fontSizeXxs, labelGray, montserrat } from 'style/constants';

const Label = styled.p`
  font-family: ${montserrat};
  font-weight: 700;
  font-size: ${fontSizeXxs};
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${labelGray};
`;

export default Label;
