import styled from 'styled-components';
import { blueNavy, fontSizeXl, montserrat, paddingSm } from 'style/constants';

const H4 = styled.h4`
  font-family: ${montserrat};
  font-weight: 500;
  font-size: ${fontSizeXl};
  line-height: ${fontSizeXl};
  color: ${blueNavy};
  padding-bottom: ${paddingSm};
`;

export default H4;
