import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import Container from './BaseFeature';
import {
  blueGrayActive,
  blueActive,
  blueDark,
  borderRadiusMd,
  borderRadiusLg,
  cardShadow,
  grayDark,
  white,
} from 'style/constants';
import ClockIcon from 'static/img/clock.svg';
import dashboardContentSvg from 'static/img/cards/dashboard.svg';
import UpdateIcon from 'static/img/update.svg';
import UserIcon from 'static/img/user.svg';
import Wave from 'static/img/wave.svg';

const ThreeLinesWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, -${parallax}px, 0px)`,
    transform: `translate3d(0px, -${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  left: 22%;
  top: 18%;
  width: 7%;
  height: 1%;
  opacity: 0.45;

  div {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: #071652;
    background-clip: padding-box;
    backface-visibility: hidden;
    perspective: 1000;
    will-change: transform;
    z-index: 12;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0%;
      transform: translateX(200%);
      height: 100%;
      width: 14%;
      border-radius: 10px;
      background: #071652;
      background-clip: padding-box;
    }

    &:nth-child(2) {
      transform: translate(70%, 200%);
    }

    &:nth-child(3) {
      transform: translate(10%, 400%);
      background: linear-gradient(to right, #071652, #07165200 120%);
      background-clip: padding-box;

      &::before {
        left: 0%;
        right: initial;
        transform: translateX(-200%);
      }
    }
  }

  ${props =>
    (props.bottom &&
      css`
        left: 51%;
        top: initial;
        bottom: 6%;
        z-index: 10;
        width: 5%;

        div {
          background: #09238b;

          &::before {
            background: #09238b;
            width: 19%;
          }

          &:nth-child(2) {
            transform: translate(70%, 175%);
          }

          &:nth-child(3) {
            transform: translate(10%, 350%);
            background: #09238b;
          }
        }
      `) ||
    (props.left &&
      css`
        left: 14%;
        top: 63%;
        z-index: 10;
        width: 5%;

        div {
          &::before {
            width: 20%;
          }

          &:nth-child(2) {
            transform: translate(70%, 175%);
          }

          &:nth-child(3) {
            transform: translate(10%, 350%);
          }
        }
      `)};
`;

const ThreeLines = ({ parallax, bottom, left }) => (
  <ThreeLinesWrapper parallax={parallax} bottom={bottom} left={left}>
    {[0, 1, 2].map(i => (
      <div key={i} />
    ))}
  </ThreeLinesWrapper>
);

const DoubleLine = styled.div`
  position: absolute;
  top: 47%;
  left: 62%;
  width: 13%;
  height: 1%;
  border-radius: 10px;
  background: ${blueActive};
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 22%;
    transform: translateY(200%);
    height: 100%;
    width: 60%;
    border-radius: 15px;
    background: linear-gradient(to left, ${blueActive}, ${blueActive}00 120%);
    background-clip: padding-box;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 2%;
    transform: translateY(200%);
    height: 100%;
    width: 8%;
    border-radius: 15px;
    background: ${blueActive};
    background-clip: padding-box;
  }

  ${props =>
    props.dark &&
    css`
      top: initial;
      bottom: 16%;
      left: 30%;
      background: #2844b6;
      z-index: 10;
      width: 15%;
      height: 1.5%;

      &::before {
        background: linear-gradient(to left, #2844b6, #2844b600 120%);
      }

      &::after {
        width: 10%;
        background: #2844b6;
      }
    `};
`;

const Gradient = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax || 0}px, 0px)`,
    transform: `translate3d(0px, ${parallax || 0}px, 0px)`,
  },
}))`
  position: absolute;
  top: 29%;
  left: 16%;
  width: 15%;
  height: 15%;
  opacity: 0.3;
  border-radius: ${borderRadiusLg};
  background: #c5d5fb;
  will-change: transform;
  z-index: 10;

  ${props =>
    (props.middle &&
      css`
        top: 37%;
        left: initial;
        right: -5%;
        width: 20%;
        background: #c5d5fb;
        height: 12%;

        @media (max-width: 1000px) {
          top: 6%;
          right: 23%;
          height: 13%;
          border-radius: ${borderRadiusMd};
          background: linear-gradient(to bottom, #c5d5fb, #c5d5fb00 140%);
        }
      `) ||
    (props.right &&
      css`
        top: initial;
        bottom: 5%;
        left: initial;
        right: 30%;
        width: 13%;
        height: 10%;
        z-index: 10;
        background: linear-gradient(to bottom, #c5d5fb, #c5d5fb00 130%);
        background-clip: padding-box;
      `) ||
    (props.bottom &&
      css`
        top: initial;
        bottom: -2%;
        width: 25%;
        height: 22%;
        left: 10%;
        z-index: 10;
      `)};
`;

const BackgroundCut = styled.div`
  position: absolute;
  top: 39%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #343fa4;
  opacity: 0.5;
`;

const BackgroundWave = styled.div`
  position: absolute;
  bottom: -2%;
  right: 0%;
  width: 175%;
  z-index: 3;

  svg {
    fill: ${blueActive};
  }
`;

const RPCardWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax}px, 0px)`,
    transform: `translate3d(0px, ${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  bottom: 10%;
  right: -3%;
  width: 49%;
  border-radius: ${borderRadiusLg};
  border: 1px solid ${grayDark};
  box-sizing: border-box;
  background-color: ${white};
  box-shadow: ${cardShadow};
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  overflow: hidden;
  padding: 2%;

  @media (max-width: 1000px) {
    right: 0%;
  }
`;

const RPCardTop = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 6%;
`;

const RPCardIcon = styled.div`
  display: flex;
  width: 15%;
  padding-top: 15%;
  background: #dfb1fd;
  border-radius: ${borderRadiusMd};
  margin-right: 5%;
  flex-shrink: 0;
`;

const RPCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const RPCardTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 30%;
  border-radius: 10px;
  background: #dbdce4;

  ${props =>
    props.small &&
    css`
      width: 55%;
      padding-top: 8%;
      background: #dfb1fd;
      margin-bottom: 10%;
    `};
`;

const RPCardChip = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 46%;
  border-radius: 15px;
  margin-top: auto;
  background: #f2f3f7;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 6%;
    transform: translateY(-50%);
    width: 10%;
    padding-top: 10%;
    border-radius: 50%;
    box-shadow: inset 0px 0px 0px 4px #d8d8d8;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 24%;
    transform: translateY(-50%);
    width: 67%;
    height: 25%;
    border-radius: 10px;
    background: #d8d8d8;
  }
`;

const RPCardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 6%;

  div {
    width: 85%;
    padding-top: 3%;
    border-radius: 10px;
    background: #dbdce4;
    margin-bottom: 4%;

    &:nth-child(2) {
      width: 65%;
      margin-bottom: 0;
    }
  }
`;

const RPCardCollaborators = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: ${props => (props.small ? 0 : '6%')};
`;

const RPCardCollaborator = styled.div`
  position: relative;
  width: 8%;
  padding-top: 8%;
  background: #dbdce4;
  border-radius: 50%;
  margin-right: 2%;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    color: #b6b8bf;
    stroke-width: 3;
  }

  ${props =>
    props.small &&
    css`
      width: 9%;
      padding-top: 9%;
    `};
`;

const RPCardProgress = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 3%;
  background: ${blueGrayActive};
  border-radius: 10px;
  margin-bottom: 2%;
  overflow: hidden;
  background-clip: padding-box;
  z-index: 2;

  ${props =>
    props.small &&
    css`
      padding-top: 6%;
      margin-bottom: 6%;
    `};
`;

const RPCardProgressActive = styled.div.attrs(({ max, progress, reverse }) => {
  const translateX =
    (reverse ? 60 : 40) + (progress / max) * (reverse ? 40 : 60);
  return {
    style: {
      WebkitTransform: `translateX(-${translateX}%)`,
      transform: `translateX(-${translateX}%)`,
    },
  };
})`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transform-origin: left;
  background: ${blueActive};
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  z-index: -1;
`;

const RiskProfileCard = ({ max, parallax }) => (
  <RPCardWrapper parallax={parallax}>
    <RPCardTop>
      <RPCardIcon />
      <RPCardHeader>
        <RPCardTitle />
        <RPCardChip />
      </RPCardHeader>
    </RPCardTop>
    <RPCardBody>
      {[0, 1].map(i => (
        <div key={i} />
      ))}
    </RPCardBody>
    <RPCardCollaborators>
      {[0, 1, 2].map(i => (
        <RPCardCollaborator key={i}>
          <UserIcon />
        </RPCardCollaborator>
      ))}
    </RPCardCollaborators>
    <RPCardProgress>
      <RPCardProgressActive progress={parallax} max={max} />
    </RPCardProgress>
  </RPCardWrapper>
);

const GeneralRPCardWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax}px, 0px)`,
    transform: `translate3d(0px, ${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  top: 25%;
  left: 56%;
  width: 23%;
  border-radius: ${borderRadiusLg};
  border: 1px solid ${grayDark};
  box-sizing: border-box;
  background-color: ${white};
  box-shadow: ${cardShadow};
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  overflow: hidden;
  padding: 2%;

  @media (max-width: 1000px) {
    left: 49%;
  }
`;

const RPCardCaption = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  padding-top: 6%;
  border-radius: 10px;
  margin-bottom: 5%;
  background: #dbdce4;
`;

const GeneralRPCard = ({ parallax }) => (
  <GeneralRPCardWrapper parallax={parallax}>
    <RPCardTitle small />
    <RPCardCaption />
    <RPCardProgress small>
      <RPCardProgressActive progress={parallax} max={100} reverse />
    </RPCardProgress>
    <RPCardBody>
      {[0, 1].map(i => (
        <div key={i} />
      ))}
    </RPCardBody>
    <RPCardCollaborators small>
      {[0, 1, 2].map(i => (
        <RPCardCollaborator key={i} small>
          <UserIcon />
        </RPCardCollaborator>
      ))}
    </RPCardCollaborators>
  </GeneralRPCardWrapper>
);

const DashboardCardWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax}px, 0px)`,
    transform: `translate3d(0px, ${parallax}px, 0px)`,
  },
}))`
  position: absolute;
  top: 33%;
  left: -5%;
  width: 46%;
  height: 30%;
  border-radius: ${borderRadiusLg};
  border: 1px solid ${grayDark};
  box-sizing: border-box;
  background-color: ${white};
  box-shadow: ${cardShadow};
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
  overflow: hidden;
`;

const DashboardHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 8%;
  z-index: 10;
`;

const DashboardContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  z-index: 9;
`;

const DashboardContent = styled(dashboardContentSvg).attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, -${parallax}px, 0px)`,
    transform: `translate3d(0px, -${parallax}px, 0px)`,
  },
}))`
  width: 100%;
  height: fit-content;
  margin-bottom: -4px;
  backface-visibility: hidden;
  perspective: 1000;
  will-change: transform;
`;

const DashboardCard = ({ contentParallax, parallax }) => (
  <DashboardCardWrapper parallax={parallax}>
    <DashboardHeader />
    <DashboardContentWrapper>
      <DashboardContent parallax={contentParallax} />
    </DashboardContentWrapper>
  </DashboardCardWrapper>
);

const ChipWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(${parallax || 0}px, 0px, 0px)`,
    transform: `translate3d(${parallax || 0}px, 0px, 0px)`,
  },
}))`
  position: absolute;
  left: 46%;
  top: 27%;
  width: 16%;
  height: 2.5%;
  background: ${white};
  opacity: 0.2;
  border-radius: 20px;
  will-change: transform;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 22%;
    transform: translateY(-50%);
    width: 68%;
    height: 25%;
    border-radius: 15px;
    opacity: 0.6;
    background: linear-gradient(to right, #1f3fc0 50%, #1f3fc000 110%);
    background-clip: padding-box;
  }

  @media (max-width: 1000px) {
    left: 42%;
  }

  ${props =>
    props.purple &&
    css`
      left: 2%;
      top: initial;
      bottom: 23%;
      width: 25%;
      height: 4.5%;
      opacity: 1;
      background: #dfb1fd;
      border-radius: 30px;

      &::after {
        background: #c78aef;
        left: 24%;
        width: 66%;
        height: 30%;
        opacity: 1;
      }

      @media (max-width: 1000px) {
        left: 2%;
      }
    `};
`;

const ChipIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 7%;
  width: 9%;
  padding-top: 9%;
  border-radius: 50%;
  opacity: 0.6;
  box-shadow: inset 0 0 0 4px #1f3fc0;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    stroke-width: 3;
  }

  ${props =>
    props.purple &&
    css`
      box-shadow: none;
      opacity: 1;
      width: 11%;
      padding-top: 11%;

      svg {
        color: #c78aef;
      }
    `};
`;

const Chip = ({ parallax, purple }) => (
  <ChipWrapper purple={purple} parallax={parallax}>
    <ChipIcon purple={purple}>{purple && <ClockIcon />}</ChipIcon>
  </ChipWrapper>
);

const CollaboratorWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax || 0}px, 0px)`,
    transform: `translate3d(0px, ${parallax || 0}px, 0px)`,
  },
}))`
  position: absolute;
  left: 35%;
  top: 50%;
  width: 22%;
  height: 6%;
  background: ${blueActive};
  border-radius: ${borderRadiusLg};
  will-change: transform;
  z-index: 14;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 32%;
    height: 15%;
    width: 36%;
    background: #88a0ef;
    border-radius: 10px;
    opacity: 0.5;
  }

  &::before {
    top: 27%;
  }

  &::after {
    bottom: 27%;
    width: 55%;
    background: linear-gradient(to right, #88a0ef, #88a0ef00 120%);
    background-clip: padding-box;
  }

  ${props =>
    (props.purple &&
      css`
        left: initial;
        right: 2%;
        top: 43.5%;
        width: 16%;
        height: 4%;
        background: #dfb1fd;

        &::before,
        &::after {
          opacity: 1;
          background: #c78aef;
        }
      `) ||
    (props.floating &&
      css`
        top: initial;
        bottom: 27%;
        left: 40%;
        background: ${blueActive};

        &::before,
        &::after {
          background: #828ef9;
        }
      `) ||
    (props.dark &&
      css`
        top: 27%;
        left: initial;
        right: 12%;
        width: 14%;
        height: 4%;
        background: #1f3fc0;

        &::before,
        &::after {
          height: 13%;
          background: #7a94e8;
        }

        @media (max-width: 1000px) {
          right: 20%;
        }
      `)};
`;

const CollaboratorIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 9%;
  transform: translateY(-50%);
  width: 14%;
  padding-top: 14%;
  border-radius: 50%;
  background: #88a0ef;
  opacity: 0.5;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    stroke-width: 2.5;
    color: #2a3faf;
  }

  ${props =>
    (props.purple &&
      css`
        background: #c78aef;
        opacity: 1;
        left: 10%;
        width: 12%;
        padding-top: 12%;
      `) ||
    (props.dark &&
      css`
        left: 10%;
        width: 12%;
        padding-top: 12%;
      `)};
`;

const CollaboratorCard = ({ parallax, purple, floating, dark }) => (
  <CollaboratorWrapper
    parallax={parallax || 0}
    purple={purple}
    floating={floating}
    dark={dark}
  >
    <CollaboratorIcon purple={purple} floating={floating} dark={dark}>
      {!purple && !floating && !dark && <UpdateIcon />}
    </CollaboratorIcon>
  </CollaboratorWrapper>
);

const FloatingIconWrapper = styled.div.attrs(({ parallax }) => ({
  style: {
    WebkitTransform: `translate3d(0px, ${parallax || 0}px, 0px)`,
    transform: `translate3d(0px, ${parallax || 0}px, 0px)`,
  },
}))`
  position: absolute;
  top: 9%;
  left: 34%;
  width: 4%;
  padding-top: 4%;
  border-radius: 50%;
  background: #3e66df;
  opacity: 0.7;
  transform: scale(0.8);
  will-change: transform;

  svg {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    color: #2a40b1;
    stroke-width: 2.5;
  }

  ${props =>
    (props.right &&
      css`
        top: 19%;
        left: initial;
        right: 14%;
        width: 3%;
        padding-top: 3%;
      `) ||
    (props.middle &&
      css`
        opacity: 1;
        background: ${blueActive};
        top: 42%;
        left: 48%;
        width: 3%;
        padding-top: 3%;
        z-index: 10;
      `) ||
    (props.bottom &&
      css`
        background: #343fa4;
        top: 66%;
        left: 33%;
        width: 3%;
        padding-top: 3%;
        z-index: 10;

        svg {
          color: ${blueActive};
        }
      `)};
`;

const FloatingIcon = ({ parallax, right, middle, bottom }) => (
  <FloatingIconWrapper
    parallax={parallax}
    right={right}
    middle={middle}
    bottom={bottom}
  >
    <UserIcon />
  </FloatingIconWrapper>
);

class HeroDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: {
        top: blueDark,
        bottom: blueDark,
      },
      initialParallax: {
        rpCard: {
          depth: -0.07,
          height: 0,
          initialHeight: 0,
          endHeight: 50,
        },
        downCards: {
          depth: 0.05,
          height: 0,
          initialHeight: 0,
          endHeight: 50,
        },
        dashboardContent: {
          depth: 0.1,
          height: 0,
          initialHeight: 0,
          endHeight: 130,
        },
        chip: {
          depth: 0.05,
          height: 0,
          initialHeight: 0,
          endHeight: 100,
        },
        collabCard: {
          depth: -0.1,
          height: 0,
          initialHeight: 0,
          endHeight: -100,
        },
        purpleCollab: {
          depth: -0.2,
          height: 0,
          initialHeight: 0,
          endHeight: -300,
        },
      },
    };

    this.renderBackground = this.renderBackground.bind(this);
    this.renderForeground = this.renderForeground.bind(this);
  }

  renderBackground() {
    return (
      <Fragment>
        <CollaboratorCard floating />
        <Chip />
        <ThreeLines />
        <ThreeLines left />
        <ThreeLines bottom />
        <Gradient />
        <Gradient middle />
        <Gradient right />
        <Gradient bottom />
        <FloatingIcon />
        <FloatingIcon right />
        <FloatingIcon middle />
        <FloatingIcon bottom />
        <DoubleLine />
        <DoubleLine dark />
        <BackgroundCut />
        <BackgroundWave>
          <Wave />
        </BackgroundWave>
      </Fragment>
    );
  }

  renderForeground(parallax) {
    return (
      <Fragment>
        <CollaboratorCard parallax={parallax.collabCard.height} />
        <CollaboratorCard parallax={parallax.purpleCollab.height} purple />
        <CollaboratorCard parallax={parallax.downCards.height} dark />
        <Chip purple parallax={parallax.chip.height} />
        <GeneralRPCard parallax={parallax.rpCard.height} />
        <RiskProfileCard
          parallax={parallax.rpCard.height}
          max={parallax.dashboardContent.endHeight}
        />
        <DashboardCard
          parallax={parallax.downCards.height}
          contentParallax={parallax.dashboardContent.height}
        />
      </Fragment>
    );
  }

  render() {
    return (
      <Container
        ref={e => (this.container = e)}
        theme={this.state.theme}
        show
        parallax={this.state.initialParallax}
        aboveTop
        triggerHeight={0}
        renderBackground={this.renderBackground}
        renderForeground={this.renderForeground}
      />
    );
  }
}

export default HeroDesktop;
