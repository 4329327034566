import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'style';
import {
  blueActive,
  blueGrayActive,
  blueGrayDark,
  borderRadiusMd,
  fontSizeXs,
  openSans,
  transitionSimpleXtraFast,
} from 'style/constants';

const buttonStyles = css`
  font-family: ${openSans};
  font-weight: 700;
  font-size: ${fontSizeXs};
  line-height: ${fontSizeXs};
  color: ${blueActive};
  padding: 8px 12px;
  background: transparent;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: ${borderRadiusMd};
  position: relative;
  box-shadow: 0 0 0 0px ${blueGrayDark};
  transition: ${transitionSimpleXtraFast};

  &:hover,
  &:focus {
    background: ${blueGrayActive};
  }

  &:focus,
  &:active {
    box-shadow: 0 0 0 2px ${blueGrayDark};
  }

  &:active {
    background: ${blueGrayDark};
  }
`;

const Button = styled.button([buttonStyles]);

const ButtonLink = styled(Link)([buttonStyles]);

const TextButton = ({ children, ...props }) => {
  return props.to ? (
    <ButtonLink {...props}>{children}</ButtonLink>
  ) : (
    <Button {...props}>{children}</Button>
  );
};

export default TextButton;
