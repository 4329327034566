import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setNavHeight, toggleScroll } from 'actions/nav';
import { StaticQuery, graphql } from 'gatsby';
import { Button, Link, Logo } from 'style';
import {
  blueGrayActive,
  blueGrayDark,
  blueActive,
  blueDark,
  blueNavy,
  bodyGray,
  borderRadiusMd,
  desktopWidth,
  mobileHdWidth,
  mobileNavWidth,
  paddingMd,
  paddingXxl,
  paddingWrapperSm,
  paddingWrapperMd,
  tabletWidth,
  transitionSimpleXtraFast,
  white,
} from 'style/constants';
import MenuSvg from 'static/img/menu.svg';
import CloseSvg from 'static/img/close.svg';

const Container = styled.nav.attrs({
  role: 'navigation',
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: ${paddingWrapperSm};
  padding: ${paddingWrapperSm} ${paddingWrapperMd};
  background-color: ${props => (props.locked ? white : 'transparent')};
  box-sizing: border-box;
  transition: 0.2s ease-in-out;
  z-index: 999;
  box-shadow: 0px 3px 7px
    rgba(0, 0, 0, ${props => (props.locked && !props.hide ? 0.1 : 0)});
  transform: translateY(
    calc(
      ${props => (props.hide ? -props.top : 0)}px -
        ${props => (props.locked ? paddingWrapperSm : '0px')}
    )
  );
  height: 6rem;

  @media (max-width: ${tabletWidth}) {
    margin-top: 0;
    padding: ${paddingWrapperSm} ${paddingWrapperMd};
    transform: translateY(calc(${props => (props.hide ? -props.top : 0)}px));
  }

  @media (max-width: ${mobileNavWidth}) {
    ${props =>
      props.mobileMenuVisible &&
      css`
        transform: none;
      `};
  }

  @media (max-width: ${mobileHdWidth}) {
    padding: ${paddingWrapperSm};
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${paddingWrapperSm};
    background: ${props => (props.locked ? white : 'transparent')};
    transition: 0.2s ease-in-out;
    transform: translateY(-100%);
  }
`;

const Wrapper = styled.div`
  height: 100%;
  margin: 0 auto;
  width: 100%;
  max-width: ${desktopWidth};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  z-index: 1001;
  align-items: center;

  .logo {
    display: inline;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: ${mobileNavWidth}) {
      max-width: 180px;
    }
  }
`;

const DesktopLinks = styled.div`
  display: inline;
  flex-direction: row;
  align-items: center;

  a {
    margin-left: ${paddingXxl};

    &:not(:last-child) {
      color: ${props => (props.inverse ? white : bodyGray)};
      outline: none;

      &:hover,
      &:focus {
        text-decoration: underline;
        text-decoration-color: ${props => (props.inverse ? white : bodyGray)};
      }

      @media (max-width: ${tabletWidth}) {
        color: ${bodyGray};

        &:hover,
        &:focus {
          text-decoration-color: ${bodyGray};
        }
      }
    }

    @media (max-width: ${tabletWidth}) {
      &:last-child {
        background: ${blueActive};
        color: ${white};

        &:hover,
        &:active {
          background: ${blueDark};
        }
      }
    }
  }

  @media (max-width: ${mobileNavWidth}) {
    display: none;
  }
`;

const MobileMenu = styled.button`
  display: none;
  flex-direction: column;
  padding: ${paddingMd};
  background: transparent;
  border: none;
  outline: none;
  border-radius: ${borderRadiusMd};
  box-shadow: 0 0 0 0px ${blueGrayDark};
  transform: translateX(${paddingMd});
  box-sizing: border-box;
  cursor: pointer;
  transition: ${transitionSimpleXtraFast};
  width: 44px;

  @media (max-width: ${mobileNavWidth}) {
    display: flex;
  }

  svg {
    width: 100%;
    margin-left: ${props => (props.closed ? 1 : 0)}px;
    color: ${props => (props.closed ? white : blueNavy)};
  }

  &:focus {
    background: ${blueGrayActive};

    svg {
      color: ${blueNavy};
    }
  }

  &:focus,
  &:active {
    box-shadow: 0 0 0 2px ${blueGrayDark};
  }

  &:active {
    background: ${blueGrayDark};
  }
`;

const MobileWrapper = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${blueActive};
  z-index: 1000;
  padding-top: ${props => props.top}px;

  @media (max-width: ${mobileNavWidth}) {
    display: block;
  }
`;

const MobileLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;

  a {
    &:not(:last-child) {
      color: ${white};
      margin-bottom: 30px;
      outline: none;

      &:hover,
      &:focus {
        text-decoration: underline;
        text-decoration-color: ${white};
      }
    }
  }
`;

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevScrollPos: 0,
      hideNav: false,
      locked: false,
      mobileMenuVisible: false,
    };
    this.checkScroll = this.checkScroll.bind(this);
    this.resize = this.resize.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.toggleScroll = this.toggleScroll.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.toggleScroll();
    this.checkScroll();
    window.addEventListener('scroll', this.checkScroll);
    this.resize();
    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.resize);
    window.cancelAnimationFrame(this.checkScroll);
  }

  checkScroll() {
    const currScrollPos = window.pageYOffset;
    this.setState({
      prevScrollPos: currScrollPos,
      locked: currScrollPos >= 50,
      hideNav: currScrollPos >= 50 && this.state.prevScrollPos < currScrollPos,
    });
  }

  resize() {
    if (this.nav) {
      this.props.setNavHeight(this.nav.clientHeight);
    }
    if (
      this.state.mobileMenuVisible &&
      window.innerWidth > parseInt(mobileNavWidth)
    ) {
      this.toggleMobileMenu(false);
    }
  }

  setState(newState, callback) {
    if (this.mounted) {
      super.setState(newState, callback);
    }
  }

  toggleMobileMenu(force) {
    this.setState(
      {
        mobileMenuVisible:
          force !== undefined ? force : !this.state.mobileMenuVisible,
      },
      this.toggleScroll
    );
  }

  toggleScroll() {
    if (this.state.mobileMenuVisible) {
      this.props.toggleScroll(true);
      document.body.addEventListener(
        'touchmove',
        e => {
          e.preventDefault();
        },
        { passive: false }
      );
    } else {
      this.props.toggleScroll(false);
      document.body.removeEventListener(
        'touchmove',
        e => {
          e.preventDefault();
        },
        { passive: false }
      );
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query NavQuery {
            nav: prismicNav {
              data {
                cta_text
                cta_href {
                  url
                }
                main_links {
                  link_text
                  link_href {
                    url
                  }
                }
              }
            }
          }
        `}
        render={({ nav }) => {
          const { height, home } = this.props;
          const { hideNav, locked, mobileMenuVisible } = this.state;
          const { cta_text, cta_href, main_links } = nav.data;
          return (
            <Fragment>
              <Container
                top={height}
                hide={hideNav}
                locked={locked}
                mobileMenuVisible={mobileMenuVisible}
                ref={e => (this.nav = e)}
              >
                <Wrapper>
                  <Logo
                    isLink
                    white={mobileMenuVisible}
                    onClick={() => this.toggleMobileMenu(false)}
                  />
                  <DesktopLinks inverse={home && !locked}>
                    {main_links.map((link, i) => (
                      <Link to={link.link_href.url} key={i}>
                        {link.link_text}
                      </Link>
                    ))}
                    <Button inverse={home && !locked} to={cta_href.url}>
                      {cta_text}
                    </Button>
                  </DesktopLinks>
                  <MobileMenu
                    closed={mobileMenuVisible}
                    ref={e => (this.mobileMenu = e)}
                    onClick={() => this.toggleMobileMenu()}
                  >
                    {mobileMenuVisible ? <CloseSvg /> : <MenuSvg />}
                  </MobileMenu>
                </Wrapper>
                {mobileMenuVisible && (
                  <MobileWrapper top={height}>
                    <MobileLinks>
                      {main_links.map((link, i) => (
                        <Link
                          to={link.link_href.url}
                          key={i}
                          onClick={() => this.toggleMobileMenu(false)}
                        >
                          {link.link_text}
                        </Link>
                      ))}
                      <Button
                        inverse
                        to={cta_href.url}
                        onClick={() => this.toggleMobileMenu(false)}
                      >
                        {cta_text}
                      </Button>
                    </MobileLinks>
                  </MobileWrapper>
                )}
              </Container>
            </Fragment>
          );
        }}
      />
    );
  }
}

Nav.propTypes = {
  height: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  height: state.nav.height,
});

export default connect(mapStateToProps, { setNavHeight, toggleScroll })(Nav);
