import React from 'react';
import styled from 'styled-components';
import { claim, notification, phone, users } from 'static/img/icon';

const ICONS = {
  claim: {
    src: claim,
    alt: 'Claim',
  },
  notification: {
    src: notification,
    alt: 'Notification',
  },
  phone: {
    src: phone,
    alt: 'Phone',
  },
  users: {
    src: users,
    alt: 'Users',
  },
};

const Wrapper = styled.div`
  height: 32px;
  width: 32px;

  svg {
    height: 100%;
    width: 100%;
  }
`;

const Icon = ({ name }) => {
  const Source = ICONS[name].src;
  return (
    <Wrapper>
      <Source />
    </Wrapper>
  );
};

export default Icon;
