import { SET_NAV_HEIGHT, TOGGLE_DISABLE_SCROLL } from 'actions/types';

export const setNavHeight = height => dispatch => {
  dispatch({
    type: SET_NAV_HEIGHT,
    payload: height,
  });
};

export const toggleScroll = disable => dispatch => {
  dispatch({
    type: TOGGLE_DISABLE_SCROLL,
    payload: disable,
  });
};
