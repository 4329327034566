import styled from 'styled-components';
import { bodyGray, fontSizeMd, fontSizeXs, openSans, paddingMd } from 'style/constants';

const P = styled.p`
  color: ${bodyGray};
  font-size: ${fontSizeMd};
  line-height: 1.7;
  font-family: ${openSans};
  padding-bottom: ${paddingMd};
`;

export default P;
