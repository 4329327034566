import styled from 'styled-components';
import { blueNavy, fontSizeLg, montserrat } from 'style/constants';

const H5 = styled.h5`
  font-size: ${fontSizeLg};
  font-family: ${montserrat};
  font-weight: 600;
  color: ${blueNavy};
`;

export default H5;
