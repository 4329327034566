import React from 'react';
import styled, { css } from 'styled-components';
import Link from './Link';
import {
  backgroundDark,
  blueActive,
  blueDark,
  blueGrayDark,
  borderRadiusMd,
  fontSizeSm,
  openSans,
  transitionSimpleXtraFast,
  white,
} from 'style/constants';

const buttonStyles = css`
  display: inline-block;
  text-decoration: none;
  color: ${props => (props.inverse ? blueActive : white)};
  outline: none;
  border: none;
  background: ${props => (props.inverse ? white : blueActive)};
  padding: 12px 16px;
  font-family: ${openSans};
  font-size: ${fontSizeSm};
  font-weight: 600;
  box-sizing: border-box;
  border-radius: ${borderRadiusMd};
  cursor: pointer;
  box-shadow: 0 0 0 0px ${blueGrayDark};
  transition: ${transitionSimpleXtraFast};

  &:hover,
  &:active {
    background: ${props => (props.inverse ? backgroundDark : blueDark)};
  }

  &:focus,
  &:active {
    box-shadow: 0 0 0 2px ${blueGrayDark};
  }
`;

const Btn = styled.button([buttonStyles]);

const ButtonLink = styled(Link)([buttonStyles]);

const Button = ({ children, ...props }) => {
  const Component = props.to ? ButtonLink : Btn;
  return <Component {...props}>{children}</Component>;
};

export default Button;
