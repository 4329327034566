import React, { Fragment } from 'react';
import styled from 'styled-components';
import { H3 } from 'style';
import {
  backgroundGray,
  desktopWidth,
  mobileHdWidth,
  paddingWrapperSm,
  paddingWrapperMd,
  paddingWrapperLg,
  paddingWrapperXl,
  paddingWrapperXxl,
  paddingWrapperXxxl,
  tabletWidth,
} from 'style/constants';
import Wave from 'static/img/wave.svg';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

const Container = styled.div`
  z-index: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${paddingWrapperLg} ${paddingWrapperMd} 0;
  box-sizing: border-box;
  background-color: ${backgroundGray};
  padding: 3rem 0 !important;

  @media (max-width: ${tabletWidth}) {
    padding-top: ${paddingWrapperXxl};
    padding-bottom: ${paddingWrapperXxl};
  }

  @media (max-width: ${mobileHdWidth}) {
    padding-left: ${paddingWrapperSm};
    padding-right: ${paddingWrapperSm};
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: ${desktopWidth};

  h3 {
    margin: 0 auto;
    max-width: 600px;
    text-align: center;

    @media (max-width: ${tabletWidth}) {
      max-width: 400px;
    }
  }
`;

const WaveContainer = styled.div`
  display: block;
  flex-direction: column;
  color: ${backgroundGray};
  width: 100%;
  background: ${backgroundGray};
  transform: translateY(-50%);
  margin-bottom: 0;
  z-index: -1;

  @media (max-width: ${tabletWidth}) {
    display: none;
  }
`;

const About = ({ about }) => {
  return (
    <Main>
      <Container>
        <Wrapper>
          <H3>{about}</H3>
        </Wrapper>
      </Container>
      {/* <WaveContainer>
        <Wave />
      </WaveContainer> */}
    </Main>
  );
};

export default About;
